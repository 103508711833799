<template>
  <client-only style="width:100%;height:100%">
    <div class="EditAutoTour">
      <template v-if="expend">
        <div class="baseArea">
          <div v-if="autoTourPlayer" class="autoTourPlayer">
            <span class="text-shadow left">{{ moment(autoTourPlayer.currentTime).format('mm:ss.S')}}/{{moment(autoTourPlayer.totalDuration).format('mm:ss.S')}}</span>
            <a href="javascript:" class="text-white middle" v-if="autoTourPlayer.state!='play'" @click="autoTourPlayer.play"><i class="fas fa-play"></i></a>
            <a href="javascript:" class="text-white middle" v-else @click="autoTourPlayer.pause"><i class="fas fa-pause"></i></a>
            <span class="text-shadow right">
              <template v-if="editIndex!=null">
                <button v-if="frameIndex!=null" class="btn text-white mr-4" style=" padding: 2px; line-height: 12px;" @click="removeFrame(frameIndex)">
                  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <text font-weight="bold" stroke-opacity="0" stroke="#000000" transform="matrix(1.38889 0 0 1.38889 -0.140706 -0.0647314)" xml:space="preserve" text-anchor="start" font-family="'Noto Sans Mono'" font-size="6" id="svg_12" y="4.14205" x="-0.04341" fill="#ffffff">-</text>
                      <path stroke-width="1.5" stroke="#9e9e9e" fill-opacity="0" id="svg_8" d="m11.55997,3.66125l3.2474,4.56018l-3.2474,4.56018" opacity="undefined" fill="#000000" />
                      <path stroke-width="1.5" stroke="#ffffff" fill-opacity="0" id="svg_7" d="m3.37392,8.22143l4.27928,-6.00921l4.27928,6.00921l-4.27928,6.00921l-4.27928,-6.00921z" fill="#000000" />
                    </g>
                  </svg>
                </button>
                <button v-else class="btn text-white mr-4" style=" padding: 2px; line-height: 12px;" @click="addFrameTime($refs.LineBar.getCenter())">
                  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <text font-weight="bold" stroke-opacity="0" stroke="#000000" transform="matrix(1.38889 0 0 1.38889 -0.140706 -0.0647314)" xml:space="preserve" text-anchor="start" font-family="'Noto Sans Mono'" font-size="6" id="svg_12" y="4.14205" x="-0.04341" fill="#ffffff">+</text>
                      <path stroke-width="1.5" stroke="#9e9e9e" fill-opacity="0" id="svg_8" d="m11.55997,3.66125l3.2474,4.56018l-3.2474,4.56018" opacity="undefined" fill="#000000" />
                      <path stroke-width="1.5" stroke="#ffffff" fill-opacity="0" id="svg_7" d="m3.37392,8.22143l4.27928,-6.00921l4.27928,6.00921l-4.27928,6.00921l-4.27928,-6.00921z" fill="#000000" />
                    </g>
                  </svg>
                </button>
              </template>
              <button class="btn text-white" style=" padding: 2px; line-height: 12px;" @click="expend=false"><i class="fas fa-chevron-down"></i></button>
            </span>
          </div>
          <LineBar ref="LineBar" key="LineBar" :values="autoTour.acts" :option="{startName: 'timeline',durationName: 'duration',rowSettings:rowToRowSetting(autoTour.rows),beforeDrag: beforeDrag,canDrop:canDrop,dragDelay:dragDelay}" @itemSelected="itemSelected" @itemMoved="itemMoved" @timeChange="timeChange" @lineMoved="timeChange" @lineClick="edit(null)">
            <template v-slot:default="prop">
              <div class="lineBarItem" :class="lineBarItemClass(prop,editIndex)">
                <div class="pausePointB" v-if="getIndex(prop.data)==editIndex && prop.data.act=='pausePoint'">
                </div>
                <div class="back" v-else-if="getIndex(prop.data)==editIndex">
                  <DragingEvent class="lineButton" :stop="true" @moveStart="dragstart($event,prop.level,prop.itemDragStart,'left')" @moveing="drag($event,prop.level,prop.itemDarging,'left')" @moveEnd="dragEnd($event,prop.level,prop.itemDargEnd,'left')"><div></div></DragingEvent>
                  <DragingEvent class="lineButton" :stop="true" @moveStart="dragstart($event,prop.level,prop.itemDragStart,'right')" @moveing="drag($event,prop.level,prop.itemDarging,'right')" @moveEnd="dragEnd($event,prop.level,prop.itemDargEnd,'right')"><div></div></DragingEvent>
                </div>
                <div class="item">
                  <template v-if="prop.data.act=='subtitle'">
                    <div class="text-truncate">{{prop.data.text}}</div>
                  </template>
                  <template v-else-if="prop.data.act=='file'">
                    <div class="text-truncate">{{prop.data.file.name}}</div>
                  </template>
                  <template v-else-if="prop.data.act=='viewImage'">
                    <template v-if="getIndex(prop.data)==editIndex">
                      <KeyFrames :value="prop.data" :frameIndex="frameIndex" @frameClick="frameClick" @frameMoveEnd="frameMoveEnd"></KeyFrames>
                    </template>
                    <template v-else>
                      <div class="text-truncate">{{prop.data.text||prop.data.file.name}}</div>
                    </template>
                    <!--<div class="text-truncate">{{prop.data.file.name}}</div>-->
                  </template>
                  <template v-else-if="prop.data.act=='viewText'">
                    <template v-if="getIndex(prop.data)==editIndex">
                      <KeyFrames :value="prop.data" :frameIndex="frameIndex" @frameClick="frameClick" @frameMoveEnd="frameMoveEnd"></KeyFrames>
                    </template>
                    <template v-else>
                      <div v-html="prop.data.text" style="height: 16px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; word-break: break-all; ">
                      </div>
                    </template>
                    <!--<div class="text-truncate">{{prop.data.file.name}}</div>-->
                  </template>
                  <template v-else-if="prop.data.act=='setView'">
                    <template v-for="item in [getItem(prop.data.senceGuid)]">
                      <template v-if="getIndex(prop.data)==editIndex">
                        <KeyFrames :value="prop.data" :frameIndex="frameIndex" @frameClick="frameClick" @frameMoveEnd="frameMoveEnd"></KeyFrames>
                      </template>
                      <template v-else>
                        <div class="text-truncate">{{item.title}}</div>
                      </template>
                    </template>
                  </template>
                  <template v-else-if="prop.data.act=='playAudio'">
                    <template v-if="getIndex(prop.data)==editIndex">
                      <template v-if="prop.draging">
                        <div class="text-truncate">
                          <span v-if="prop.draging&&prop.dragType=='left'&&(prop.data.startTime + Number(prop.level.start.toFixed(0)) - prop.data.timeline)<0">时间超出范围</span>
                          <span v-if="prop.draging&&prop.dragType=='left'&&(prop.data.startTime + Number(prop.level.start.toFixed(0)) - prop.data.timeline)>=0">{{moment(prop.data.startTime + Number(prop.level.start.toFixed(0)) - prop.data.timeline).format('mm:ss.S')}}</span>
                          <span v-if="prop.draging&&prop.dragType=='right'&&moment.duration(prop.data.file.duration).asMilliseconds()<Number(prop.level.end.toFixed(0)) - Number(prop.level.start.toFixed(0))">时间超出范围</span>
                          <span v-if="prop.draging&&prop.dragType=='right'&&moment.duration(prop.data.file.duration).asMilliseconds()>=Number(prop.level.end.toFixed(0)) - Number(prop.level.start.toFixed(0))">{{moment(Number(prop.level.end.toFixed(0)) - Number(prop.level.start.toFixed(0))).format('mm:ss.S')}}</span>
                          <span v-if="prop.draging&&prop.dragType=='move'">{{moment(Number(prop.level.start.toFixed(0))).format('mm:ss.S')}}</span>
                          <span v-if="!prop.draging">{{prop.data.file.name}}</span>
                        </div>
                      </template>
                      <template v-else>
                        <KeyFrames :value="prop.data" :frameIndex="frameIndex" @frameClick="frameClick" @frameMoveEnd="frameMoveEnd"></KeyFrames>
                      </template>
                    </template>
                    <template v-else>
                      <div class="text-truncate">{{prop.data.text||prop.data.file.name}}</div>
                    </template>
                  </template>
                  <template v-else-if="prop.data.act=='setHotspot'">
                    <template v-if="getIndex(prop.data)==editIndex">
                      <KeyFrames :value="prop.data" :frameIndex="frameIndex" @frameClick="frameClick" @frameMoveEnd="frameMoveEnd"></KeyFrames>
                    </template>
                    <template v-else>
                      <div class="text-truncate">{{prop.data.hotspot.title||'标注'}}</div>
                    </template>
                  </template>
                  <template v-else-if="prop.data.act=='pausePoint'">
                    <div class="text-truncate pausePointI"><div class="pausePointIcon"></div></div>
                  </template>
                  <template v-else>
                    <div class="text-truncate">{{actName[prop.data.act]}}</div>
                  </template>
                </div>
              </div>
            </template>
            <template v-slot:ruler="prop">
              <span style="font-size:10px">
                <template v-if="prop.step<1000">
                  <template v-if="prop.data%1000==0">
                    {{ moment(prop.data).format('mm:ss')}}
                  </template>
                  <template v-else>
                    {{ moment(prop.data).format('.S')}}
                  </template>
                </template>
                <template v-else>
                  {{ moment(prop.data).format('mm:ss')}}
                </template>
              </span>
            </template>
            <template v-slot:rowRight="prop">
              <template v-if="prop.data.setting.type=='view'&&prop.data.setting.guid==firstViewRow.guid">
                <div style="width:100%;height:100%;">
                  <div class="btn" style="background-color:white;font-size:12px;padding:0 3px;margin-right:3px;" @click.stop="addActTime($refs.LineBar.getCenter(),'setView')" @mousedown.stop="stop" @mousemove.stop="stop" @mouseup.stop="stop" @touchstart.stop="stop" @touchmove.stop="stop" @touchend.stop="stop">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
              </template>
            </template>
            <template v-slot:rowBack="prop">
              <div></div>
            </template>
          </LineBar>
        </div>
        <div class="controlBar">
          <div class="toolbar">
            <div class="mx-2" v-if="toolbar==null">
              <button class="btn toorbarBtn" @click="toolbar='view'">
                <div><i class="fas fa-film"></i></div>
                <div>动画</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='audio'">
                <div><i class="fas fa-music"></i></div>
                <div>音频</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='viewText'">
                <div><i class="fas fa-font"></i></div>
                <div>文字</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='image'">
                <div><i class="fas fa-image"></i></div>
                <div>图片</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='hotspot'">
                <div><i class="fas fa-crosshairs"></i></div>
                <div>标注</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='subtitle'">
                <div><i class="fas fa-align-left"></i></div>
                <div>字幕</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='points'">
                <div><i class="fas fa-thumbtack"></i></div>
                <div>控制点</div>
              </button>
            </div>
            <div v-if="toolbar=='view'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='sencePanel'">
                <div><i class="fas fa-th-large"></i></div>
                <div>场景</div>
              </button>
              <!--<button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='viewPanel'">
                <div><i class="fas fa-street-view"></i></div>
                <div>视角</div>
              </button>-->
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='viewPanel';imageProperty='view'">
                <div><i class="fas fa-sliders-h"></i></div>
                <div>编辑</div>
              </button>
              <button class="btn toorbarBtn" @click="toolbar='panoView'">
                <div><i class="fas fa-expand"></i></div>
                <div>视图</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="cutAct($refs.LineBar.getCenter())">
                <div><i class="fas fa-cut"></i></div>
                <div>分割</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="copyAct()">
                <div><i class="far fa-clone"></i></div>
                <div>复制</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='audio'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="addFile($refs.LineBar.getCenter(),'Audio')">
                <div><i class="fas fa-file-medical"></i></div>
                <div>添加</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='audioEdit';imageProperty='setting'">
                <div><i class="fas fa-sliders-h"></i></div>
                <div>编辑</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="cutAct($refs.LineBar.getCenter())">
                <div><i class="fas fa-cut"></i></div>
                <div>分割</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="copyAct()">
                <div><i class="far fa-clone"></i></div>
                <div>复制</div>
              </button>
              <!--<button class="btn toorbarBtn">
                <div><i class="fas fa-microphone"></i></div>
                <div>录音</div>
              </button>-->
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='subtitle'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="addActTime($refs.LineBar.getCenter(),'subtitle');toolbar='subtitleEdit'">
                <div><i class="fas fa-font"></i>+</div>
                <div>添加</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='subtitleEdit'">
                <div><i class="fas fa-font"></i></div>
                <div>编辑</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="cutAct($refs.LineBar.getCenter())">
                <div><i class="fas fa-cut"></i></div>
                <div>分割</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="copyAct()">
                <div><i class="far fa-clone"></i></div>
                <div>复制</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='points'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="addActTime($refs.LineBar.getCenter(),'pausePoint');">
                <div><i class="fas fa-pause"></i></div>
                <div>暂停</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='pointEdit'">
                <div><i class="fas fa-sliders-h"></i></div>
                <div>编辑</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='viewText'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="addActTime($refs.LineBar.getCenter(),'viewText');toolbar='textEdit';imageProperty='text'">
                <div><i class="fas fa-font"></i>+</div>
                <div>添加</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='textEdit';imageProperty='text'">
                <div><i class="fas fa-sliders-h"></i></div>
                <div>编辑</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="cutAct($refs.LineBar.getCenter())">
                <div><i class="fas fa-cut"></i></div>
                <div>分割</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="copyAct()">
                <div><i class="far fa-clone"></i></div>
                <div>复制</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='hotspot'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="addHotspot($refs.LineBar.getCenter())">
                <div><i class="fas fa-plus"></i></div>
                <div>添加</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='hotspotEdit';addUpdateFrame()">
                <div><i class="fas fa-edit"></i></div>
                <div>编辑</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="cutAct($refs.LineBar.getCenter())">
                <div><i class="fas fa-cut"></i></div>
                <div>分割</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="copyAct()">
                <div><i class="far fa-clone"></i></div>
                <div>复制</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='image'">
              <button class="btn toorbarBtn back" style="" @click="edit(null)">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="addFile($refs.LineBar.getCenter(),'Image')">
                <div><i class="fas fa-file-medical"></i></div>
                <div>添加</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="toolbar='imageEdit'">
                <div><i class="fas fa-sliders-h"></i></div>
                <div>编辑</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="cutAct($refs.LineBar.getCenter())">
                <div><i class="fas fa-cut"></i></div>
                <div>分割</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="copyAct()">
                <div><i class="far fa-clone"></i></div>
                <div>复制</div>
              </button>
              <button v-if="editIndex!=null" class="btn toorbarBtn" @click="removeAct(editIndex)">
                <div><i class="fas fa-trash"></i></div>
                <div>删除</div>
              </button>
            </div>
            <div v-if="toolbar=='panoView'">
              <button class="btn toorbarBtn back" style="" @click="toolbar='view'">
                <div><i class="fas fa-chevron-left"></i></div>
              </button>
              <button class="btn toorbarBtn" @click="callKrpano('skin_view_normal')">
                <div><i class="fas fa-expand"></i></div>
                <div>常规</div>
              </button>
              <button class="btn toorbarBtn" @click="callKrpano('skin_view_littleplanet')">
                <div><i class="fas fa-expand"></i></div>
                <div>小行星</div>
              </button>
              <button class="btn toorbarBtn" @click="callKrpano('skin_view_fisheye')">
                <div><i class="fas fa-expand"></i></div>
                <div>鱼眼</div>
              </button>
              <button class="btn toorbarBtn" @click="callKrpano('skin_view_stereographic')">
                <div><i class="fas fa-expand"></i></div>
                <div>立体</div>
              </button>
              <button class="btn toorbarBtn" @click="callKrpano('skin_view_architectural')">
                <div><i class="fas fa-expand"></i></div>
                <div>构架</div>
              </button>
              <button class="btn toorbarBtn" @click="callKrpano('skin_view_pannini')">
                <div><i class="fas fa-expand"></i></div>
                <div>超广角</div>
              </button>
            </div>
          </div>
          <template v-for="a in [autoTour.acts[editIndex]]">
            <div v-if="toolbar=='viewPanel'" class="panel">
              <template>
                <div style="display:flex;justify-content:space-between;align-items:baseline;">
                  <!--<div title="继承上一视角" class="custom-control custom-switch switch" style="margin-left:5px;">
                    <input type="checkbox" class="custom-control-input" id="InheritView" @change="updataStartView" v-model="a.inheritView">
                    <label class="custom-control-label" for="InheritView">继承上一视角</label>
                  </div>-->
                  <div></div>
                  <button class="btn panelBtn" @click="toolbar='view';imageProperty=null"><i class="fas fa-check"></i></button>
                </div>
                <template v-if="imageProperty=='view'">
                  <div>
                    <button class="btn toorbarBtn" @click="callKrpano('skin_view_normal')">
                      <div><i class="fas fa-expand"></i></div>
                      <div>常规</div>
                    </button>
                    <button class="btn toorbarBtn" @click="callKrpano('skin_view_littleplanet')">
                      <div><i class="fas fa-expand"></i></div>
                      <div>小行星</div>
                    </button>
                    <button class="btn toorbarBtn" @click="callKrpano('skin_view_fisheye')">
                      <div><i class="fas fa-expand"></i></div>
                      <div>鱼眼</div>
                    </button>
                    <button class="btn toorbarBtn" @click="callKrpano('skin_view_stereographic')">
                      <div><i class="fas fa-expand"></i></div>
                      <div>立体</div>
                    </button>
                    <button class="btn toorbarBtn" @click="callKrpano('skin_view_architectural')">
                      <div><i class="fas fa-expand"></i></div>
                      <div>构架</div>
                    </button>
                    <button class="btn toorbarBtn" @click="callKrpano('skin_view_pannini')">
                      <div><i class="fas fa-expand"></i></div>
                      <div>超广角</div>
                    </button>
                  </div>
                  <div>
                    <button class="btn toorbarBtn" v-if="senceGuid==a.senceGuid" @click="setView()">
                      <div><i class="fas fa-pencil-ruler"></i></div>
                      <div>更新视角</div>
                    </button>
                  </div>
                </template>
                <template v-else-if="imageProperty=='tweenType'">
                  <template v-if="editFrame">
                    <div>
                      <div style="overflow-x:auto;">
                        <div style="display:flex;">
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='Linear'}" @click="editFrame.tweenType='Linear'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px 0px'}"></div>Linear</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInQuad'}" @click="editFrame.tweenType='EaseInQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px 0px'}"></div>EaseInQuad</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutQuad'}" @click="editFrame.tweenType='EaseOutQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px 0px'}"></div>EaseOutQuad</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutQuad'}" @click="editFrame.tweenType='EaseInOutQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px 0px'}"></div>EaseInOutQuad</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInBack'}" @click="editFrame.tweenType='EaseInBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px 0px'}"></div>EaseInBack</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutBack'}" @click="editFrame.tweenType='EaseOutBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px 0px'}"></div>EaseOutBack</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutBack'}" @click="editFrame.tweenType='EaseInOutBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -46px'}"></div>EaseInOutBack</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInCubic'}" @click="editFrame.tweenType='EaseInCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -46px'}"></div>EaseInCubic</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutCubic'}" @click="editFrame.tweenType='EaseOutCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -46px'}"></div>EaseOutCubic</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInQuart'}" @click="editFrame.tweenType='EaseInQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -46px'}"></div>EaseInQuart</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutQuart'}" @click="editFrame.tweenType='EaseOutQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -46px'}"></div>EaseOutQuart</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInQuint'}" @click="editFrame.tweenType='EaseInQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -46px'}"></div>EaseInQuint</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutQuint'}" @click="editFrame.tweenType='EaseOutQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -92px'}"></div>EaseOutQuint</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInSine'}" @click="editFrame.tweenType='EaseInSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -92px'}"></div>EaseInSine</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutSine'}" @click="editFrame.tweenType='EaseOutSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -92px'}"></div>EaseOutSine</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInExpo'}" @click="editFrame.tweenType='EaseInExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -92px'}"></div>EaseInExpo</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutExpo'}" @click="editFrame.tweenType='EaseOutExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -92px'}"></div>EaseOutExpo</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInCirc'}" @click="editFrame.tweenType='EaseInCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -92px'}"></div>EaseInCirc</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutCirc'}" @click="editFrame.tweenType='EaseOutCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -138px'}"></div>EaseOutCirc</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInBounce'}" @click="editFrame.tweenType='EaseInBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -138px'}"></div>EaseInBounce</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutBounce'}" @click="editFrame.tweenType='EaseOutBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -138px'}"></div>EaseOutBounce</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInQuad'}" @click="editFrame.tweenType='EaseOutInQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -138px'}"></div>EaseOutInQuad</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutCubic'}" @click="editFrame.tweenType='EaseInOutCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -138px'}"></div>EaseInOutCubic</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInCubic'}" @click="editFrame.tweenType='EaseOutInCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -138px'}"></div>EaseOutInCubic</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutQuart'}" @click="editFrame.tweenType='EaseInOutQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -184px'}"></div>EaseInOutQuart</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInQuart'}" @click="editFrame.tweenType='EaseOutInQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -184px'}"></div>EaseOutInQuart</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutQuint'}" @click="editFrame.tweenType='EaseInOutQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -184px'}"></div>EaseInOutQuint</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInQuint'}" @click="editFrame.tweenType='EaseOutInQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -184px'}"></div>EaseOutInQuint</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutSine'}" @click="editFrame.tweenType='EaseInOutSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -184px'}"></div>EaseInOutSine</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInSine'}" @click="editFrame.tweenType='EaseOutInSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -184px'}"></div>EaseOutInSine</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInExpo'}" @click="editFrame.tweenType='EaseOutInExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -230px'}"></div>EaseOutInExpo</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutExpo'}" @click="editFrame.tweenType='EaseInOutExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -230px'}"></div>EaseInOutExpo</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutCirc'}" @click="editFrame.tweenType='EaseInOutCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -230px'}"></div>EaseInOutCirc</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInCirc'}" @click="editFrame.tweenType='EaseOutInCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -230px'}"></div>EaseOutInCirc</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseInOutBounce'}" @click="editFrame.tweenType='EaseInOutBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -230px'}"></div>EaseInOutBounce</div>
                          <div class="tweentypebox" :class="{active:editFrame.tweenType=='EaseOutInBounce'}" @click="editFrame.tweenType='EaseOutInBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -230px'}"></div>EaseOutInBounce</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="previousFrame">
                    <div>
                      <div style="overflow-x:auto;">
                        <div style="display:flex;">
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='Linear'}" @click="previousFrame.tweenType='Linear'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px 0px'}"></div>Linear</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInQuad'}" @click="previousFrame.tweenType='EaseInQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px 0px'}"></div>EaseInQuad</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutQuad'}" @click="previousFrame.tweenType='EaseOutQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px 0px'}"></div>EaseOutQuad</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutQuad'}" @click="previousFrame.tweenType='EaseInOutQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px 0px'}"></div>EaseInOutQuad</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInBack'}" @click="previousFrame.tweenType='EaseInBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px 0px'}"></div>EaseInBack</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutBack'}" @click="previousFrame.tweenType='EaseOutBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px 0px'}"></div>EaseOutBack</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutBack'}" @click="previousFrame.tweenType='EaseInOutBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -46px'}"></div>EaseInOutBack</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInCubic'}" @click="previousFrame.tweenType='EaseInCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -46px'}"></div>EaseInCubic</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutCubic'}" @click="previousFrame.tweenType='EaseOutCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -46px'}"></div>EaseOutCubic</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInQuart'}" @click="previousFrame.tweenType='EaseInQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -46px'}"></div>EaseInQuart</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutQuart'}" @click="previousFrame.tweenType='EaseOutQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -46px'}"></div>EaseOutQuart</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInQuint'}" @click="previousFrame.tweenType='EaseInQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -46px'}"></div>EaseInQuint</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutQuint'}" @click="previousFrame.tweenType='EaseOutQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -92px'}"></div>EaseOutQuint</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInSine'}" @click="previousFrame.tweenType='EaseInSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -92px'}"></div>EaseInSine</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutSine'}" @click="previousFrame.tweenType='EaseOutSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -92px'}"></div>EaseOutSine</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInExpo'}" @click="previousFrame.tweenType='EaseInExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -92px'}"></div>EaseInExpo</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutExpo'}" @click="previousFrame.tweenType='EaseOutExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -92px'}"></div>EaseOutExpo</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInCirc'}" @click="previousFrame.tweenType='EaseInCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -92px'}"></div>EaseInCirc</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutCirc'}" @click="previousFrame.tweenType='EaseOutCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -138px'}"></div>EaseOutCirc</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInBounce'}" @click="previousFrame.tweenType='EaseInBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -138px'}"></div>EaseInBounce</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutBounce'}" @click="previousFrame.tweenType='EaseOutBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -138px'}"></div>EaseOutBounce</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInQuad'}" @click="previousFrame.tweenType='EaseOutInQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -138px'}"></div>EaseOutInQuad</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutCubic'}" @click="previousFrame.tweenType='EaseInOutCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -138px'}"></div>EaseInOutCubic</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInCubic'}" @click="previousFrame.tweenType='EaseOutInCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -138px'}"></div>EaseOutInCubic</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutQuart'}" @click="previousFrame.tweenType='EaseInOutQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -184px'}"></div>EaseInOutQuart</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInQuart'}" @click="previousFrame.tweenType='EaseOutInQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -184px'}"></div>EaseOutInQuart</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutQuint'}" @click="previousFrame.tweenType='EaseInOutQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -184px'}"></div>EaseInOutQuint</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInQuint'}" @click="previousFrame.tweenType='EaseOutInQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -184px'}"></div>EaseOutInQuint</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutSine'}" @click="previousFrame.tweenType='EaseInOutSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -184px'}"></div>EaseInOutSine</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInSine'}" @click="previousFrame.tweenType='EaseOutInSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -184px'}"></div>EaseOutInSine</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInExpo'}" @click="previousFrame.tweenType='EaseOutInExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -230px'}"></div>EaseOutInExpo</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutExpo'}" @click="previousFrame.tweenType='EaseInOutExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -230px'}"></div>EaseInOutExpo</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutCirc'}" @click="previousFrame.tweenType='EaseInOutCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -230px'}"></div>EaseInOutCirc</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInCirc'}" @click="previousFrame.tweenType='EaseOutInCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -230px'}"></div>EaseOutInCirc</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseInOutBounce'}" @click="previousFrame.tweenType='EaseInOutBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -230px'}"></div>EaseInOutBounce</div>
                          <div class="tweentypebox" :class="{active:previousFrame.tweenType=='EaseOutInBounce'}" @click="previousFrame.tweenType='EaseOutInBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -230px'}"></div>EaseOutInBounce</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <div style="overflow-x:auto;">
                        <div style="display:flex;">
                          <div class="tweentypebox" :class="{active:a.tweenType=='Linear'}" @click="a.tweenType='Linear'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px 0px'}"></div>Linear</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInQuad'}" @click="a.tweenType='EaseInQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px 0px'}"></div>EaseInQuad</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutQuad'}" @click="a.tweenType='EaseOutQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px 0px'}"></div>EaseOutQuad</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutQuad'}" @click="a.tweenType='EaseInOutQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px 0px'}"></div>EaseInOutQuad</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInBack'}" @click="a.tweenType='EaseInBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px 0px'}"></div>EaseInBack</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutBack'}" @click="a.tweenType='EaseOutBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px 0px'}"></div>EaseOutBack</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutBack'}" @click="a.tweenType='EaseInOutBack'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -46px'}"></div>EaseInOutBack</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInCubic'}" @click="a.tweenType='EaseInCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -46px'}"></div>EaseInCubic</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutCubic'}" @click="a.tweenType='EaseOutCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -46px'}"></div>EaseOutCubic</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInQuart'}" @click="a.tweenType='EaseInQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -46px'}"></div>EaseInQuart</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutQuart'}" @click="a.tweenType='EaseOutQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -46px'}"></div>EaseOutQuart</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInQuint'}" @click="a.tweenType='EaseInQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -46px'}"></div>EaseInQuint</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutQuint'}" @click="a.tweenType='EaseOutQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -92px'}"></div>EaseOutQuint</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInSine'}" @click="a.tweenType='EaseInSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -92px'}"></div>EaseInSine</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutSine'}" @click="a.tweenType='EaseOutSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -92px'}"></div>EaseOutSine</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInExpo'}" @click="a.tweenType='EaseInExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -92px'}"></div>EaseInExpo</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutExpo'}" @click="a.tweenType='EaseOutExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -92px'}"></div>EaseOutExpo</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInCirc'}" @click="a.tweenType='EaseInCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -92px'}"></div>EaseInCirc</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutCirc'}" @click="a.tweenType='EaseOutCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -138px'}"></div>EaseOutCirc</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInBounce'}" @click="a.tweenType='EaseInBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -138px'}"></div>EaseInBounce</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutBounce'}" @click="a.tweenType='EaseOutBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -138px'}"></div>EaseOutBounce</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInQuad'}" @click="a.tweenType='EaseOutInQuad'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -138px'}"></div>EaseOutInQuad</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutCubic'}" @click="a.tweenType='EaseInOutCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -138px'}"></div>EaseInOutCubic</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInCubic'}" @click="a.tweenType='EaseOutInCubic'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -138px'}"></div>EaseOutInCubic</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutQuart'}" @click="a.tweenType='EaseInOutQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -184px'}"></div>EaseInOutQuart</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInQuart'}" @click="a.tweenType='EaseOutInQuart'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -184px'}"></div>EaseOutInQuart</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutQuint'}" @click="a.tweenType='EaseInOutQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -184px'}"></div>EaseInOutQuint</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInQuint'}" @click="a.tweenType='EaseOutInQuint'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -184px'}"></div>EaseOutInQuint</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutSine'}" @click="a.tweenType='EaseInOutSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -184px'}"></div>EaseInOutSine</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInSine'}" @click="a.tweenType='EaseOutInSine'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -184px'}"></div>EaseOutInSine</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInExpo'}" @click="a.tweenType='EaseOutInExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'0px -230px'}"></div>EaseOutInExpo</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutExpo'}" @click="a.tweenType='EaseInOutExpo'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-67px -230px'}"></div>EaseInOutExpo</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutCirc'}" @click="a.tweenType='EaseInOutCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-134px -230px'}"></div>EaseInOutCirc</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInCirc'}" @click="a.tweenType='EaseOutInCirc'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-201px -230px'}"></div>EaseOutInCirc</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseInOutBounce'}" @click="a.tweenType='EaseInOutBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-268px -230px'}"></div>EaseInOutBounce</div>
                          <div class="tweentypebox" :class="{active:a.tweenType=='EaseOutInBounce'}" @click="a.tweenType='EaseOutInBounce'"><div class="tweentypeimg" :style="{'background-image': `url(${$store.state.settings.filesUrl}/img/tweentypes.png)`, 'background-position':'-335px -230px'}"></div>EaseOutInBounce</div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else-if="imageProperty=='other'">
                  <div>
                    <div title="等待加载" class="custom-control custom-switch switch" style="margin-left:5px;">
                      <input type="checkbox" class="custom-control-input" id="WaitLoad" v-model="a.waitLoad">
                      <label class="custom-control-label" for="WaitLoad">等待加载</label>
                    </div>
                    <div class="ppitem" title="开始时间">
                      <label>开始时间</label>
                      <NumberButton class="nb" v-model="a.timeline" @change="autoTourPlayer.renew();timeSort()" :defaultValue="$refs.LineBar.getCenter()" :wheelRatio="1" :moveRatio="10" step="1" min="0"></NumberButton><span class="ml-1">ms</span>
                    </div>
                    <div class="ppitem" title="持续时间">
                      <label>持续时间</label>
                      <NumberButton class="nb" v-model="a.duration" @change="autoTourPlayer.renew();timeSort()" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0"></NumberButton><span class="ml-1">ms</span>
                    </div>
                    <template v-if="editFrame">
                      <div class="ppitem" title="关键帧">
                        <label>关键帧</label>
                        <NumberButton class="nb" v-model="editFrame.time" @change="autoTourPlayer.renew();" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0" :max="a.duration"></NumberButton><span class="ml-1">ms</span>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div>点击下方进行修改</div>
                </template>
                <div>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='view'}" @click="imageProperty='view'">
                    <div><i class="fas fa-expand"></i></div>
                    <div>视角</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='tweenType'}" @click="imageProperty='tweenType'">
                    <div><i class="fas fa-palette"></i></div>
                    <div>曲线</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='other'}" @click="imageProperty='other'">
                    <div><i class="fas fa-sliders-h"></i></div>
                    <div>其他</div>
                  </button>
                </div>
              </template>
            </div>
            <div v-if="toolbar=='sencePanel'" class="panel">
              <div style="display:flex;justify-content:space-between;align-items:baseline;">
                <div>
                  <TourGroups :config="{showHide:true,showGroupType:'Pano'}"></TourGroups>
                </div>
                <button class="btn panelBtn" @click="toolbar='view'"><i class="fas fa-check"></i></button>
              </div>
              <div>
                <TourGroupItems></TourGroupItems>
              </div>
            </div>
            <div v-if="toolbar=='audioEdit'" class="panel">
              <div style="display:flex;justify-content:space-between;align-items:baseline;">
                <div class="text-white" style="flex:auto">
                  <input type="text" ref="subtitleText" style="width:100%" class="form-control-sm dark" placeholder="输入文字" v-model="a.text" @change="autoTourPlayer.renew();">
                </div>
                <button class="btn panelBtn" @click="toolbar='audio';renewTime();imageProperty=null"><i class="fas fa-check"></i></button>
              </div>
              <template v-if="imageProperty=='setting'">
                <div>
                  <div title="允许暂停" class="custom-control custom-switch switch" style="margin-left:5px;">
                    <input type="checkbox" class="custom-control-input" id="CanWait" v-model="a.canWait">
                    <label class="custom-control-label" for="CanWait">允许暂停</label>
                  </div>
                  <div title="等待加载" class="custom-control custom-switch switch" style="margin-left:5px;">
                    <input type="checkbox" class="custom-control-input" id="WaitLoad" v-model="a.waitLoad">
                    <label class="custom-control-label" for="WaitLoad">等待加载</label>
                  </div>
                  <div title="循环" class="custom-control custom-switch switch" style="margin-left:5px;">
                    <input type="checkbox" class="custom-control-input" id="Loop" v-model="a.loop">
                    <label class="custom-control-label" for="Loop">循环</label>
                  </div>
                </div>
              </template>
              <template v-else-if="imageProperty=='frame'">
                <div>
                  <template v-if="editFrame">
                    <div class="ppitem" title="音量">
                      <label>音量</label>
                      <NumberButton class="nb" v-model="editFrame.volume" @change="addUpdateFrame();autoTourPlayer.renew();" :defaultValue="1" :wheelRatio="0.0001" :moveRatio="0.001" step="0.01" max="1" min="0">{{editFrame.volume*100}}%</NumberButton><span class="ml-1"></span>
                    </div>
                    <div class="ppitem" title="时间">
                      <label>时间</label>
                      <NumberButton class="nb" v-model="editFrame.time" @change="autoTourPlayer.renew();" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0" :max="a.duration">{{editFrame.time}}ms</NumberButton><span class="ml-1"></span>
                    </div>
                  </template>
                  <template v-else>
                    <button class="btn toorbarBtn" :class="{active:imageProperty=='text'}" @click="imageProperty='setting'">
                      <div><i class="fas fa-sliders-h"></i></div>
                      <div>添加</div>
                    </button>
                  </template>
                </div>
              </template>
              <template v-else-if="imageProperty=='time'">
                <div>
                  <div class="ppitem" title="开始时间">
                    <label>开始时间</label>
                    <NumberButton class="nb" v-model="a.timeline" @change="autoTourPlayer.renew();timeSort()" :defaultValue="$refs.LineBar.getCenter()" :wheelRatio="1" :moveRatio="10" step="1" min="0"></NumberButton><span class="ml-1">ms</span>
                  </div>
                  <div class="ppitem" title="持续时间">
                    <label>持续时间</label>
                    <NumberButton class="nb" v-model="a.duration" @change="autoTourPlayer.renew();timeSort()" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0"></NumberButton><span class="ml-1">ms</span>
                  </div>
                  <div class="ppitem" title="音乐起点">
                    <label>音乐起点</label>
                    <NumberButton class="nb" v-model="a.startTime" @change="autoTourPlayer.renew();timeSort()" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0"></NumberButton><span class="ml-1">ms</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div>点击下方进行修改</div>
              </template>
              <div>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='setting'}" @click="imageProperty='setting'">
                  <div><i class="fas fa-sliders-h"></i></div>
                  <div>设置</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='frame'}" @click="checkEditFrame();imageProperty='frame'">
                  <div><i class="fas fa-palette"></i></div>
                  <div>关键帧</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='time'}" @click="imageProperty='time'">
                  <div><i class="fas fa-clock"></i></div>
                  <div>时间</div>
                </button>
              </div>
            </div>
            <div v-if="toolbar=='subtitleEdit'" class="panel">
              <div style="display:flex;justify-content:space-between;align-items:baseline;">
                <div></div>
                <button v-if="a.text &&a.text!=''" class="btn panelBtn" @click="toolbar='subtitle';renewTime()"><i class="fas fa-check"></i></button>
                <button v-else class="btn panelBtn" @click="toolbar='subtitle';removeAct(editIndex)"><i class="fas fa-check"></i></button>
              </div>
              <div>
                <AutoTextarea ref="subtitleText" :maxHeight="200" style="width:100%" placeholder="输入文字" v-model="a.text"></AutoTextarea>
              </div>
            </div>
            <div v-if="toolbar=='pointEdit'" class="panel">
              <div style="display:flex;justify-content:space-between;align-items:baseline;">
                <div class="text-white" style="flex:auto">
                  <input type="text" ref="pointText" style="width:100%" class="form-control-sm dark" placeholder="输入文字" v-model="a.text" @change="">
                </div>
                <button class="btn panelBtn" @click="toolbar='points';renewTime()"><i class="fas fa-check"></i></button>
              </div>
              <div>
                <div class="ppitem" title="暂停模式">
                  <label>暂停模式</label>
                  <select v-model="a.pauseMode" @change="autoTourPlayer.renew();">
                    <option value="manual">手动继续</option>
                    <option value="auto">自动继续</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="toolbar=='textEdit'" class="panel">
              <div style="display:flex;justify-content:space-between;align-items:baseline;">
                <div class="ml-1">
                  <div v-html="a.text" style="margin-top: 5px; height: 25px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; word-break: break-all; ">
                  </div>
                </div>
                <!--<div class="text-white ml-1" style="flex:auto">
                  <input type="text" ref="textText" style="width:100%" class="form-control-sm dark" placeholder="输入文字" v-model="a.text" @change="">
                </div>-->
                <button class="btn panelBtn" @click="toolbar='viewText';renewTime();imageProperty=null;testShadowIndex=null"><i class="fas fa-check"></i></button>
                <!--<button v-if="a.text &&a.text!=''" class="btn panelBtn" @click="toolbar='viewText';renewTime()"><i class="fas fa-check"></i></button>
                <button v-else class="btn panelBtn" @click="toolbar='viewText';removeAct(editIndex)"><i class="fas fa-check"></i></button>-->
              </div>
              <div>
                <template v-if="imageProperty=='text'">
                  <div class="m-1">
                    <PellEditor v-model.trim="a.text" class="dark" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" />
                  </div>
                </template>
                <template v-if="imageProperty=='time'">
                  <div class="m-1">
                    <div class="ppitem" title="开始时间">
                      <label>开始时间</label>
                      <NumberButton class="nb" v-model="a.timeline" @change="autoTourPlayer.renew();timeSort()" :defaultValue="$refs.LineBar.getCenter()" :wheelRatio="1" :moveRatio="10" step="1" min="0"></NumberButton><span class="ml-1">ms</span>
                    </div>
                    <div class="ppitem" title="持续时间">
                      <label>持续时间</label>
                      <NumberButton class="nb" v-model="a.duration" @change="autoTourPlayer.renew();timeSort()" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0"></NumberButton><span class="ml-1">ms</span>
                    </div>
                    <template v-if="editFrame">
                      <div class="ppitem" title="关键帧">
                        <label style="width:4em">关键帧</label>
                        <NumberButton class="nb" v-model="editFrame.time" @change="autoTourPlayer.renew();" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0" :max="a.duration"></NumberButton><span class="ml-1">ms</span>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="editFrame">
                  <template v-if="imageProperty=='size'">
                    <div class="ppitem" title="宽度">
                      <label>宽度</label>
                      <NumberButton class="nb" v-model="editFrame.rect.width" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                    </div>
                    <div class="ppitem" title="高度">
                      <label>高度</label>
                      <NumberButton class="nb" v-model="editFrame.rect.height" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                    </div>
                    <div class="ppitem" title="缩放">
                      <label>缩放</label>
                      <NumberButton class="nb" v-model="editFrame.scale" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="1" :wheelRatio="-0.001" :moveRatio="0.01" step="0.001">{{(editFrame.scale*100).toFixed(1)}}</NumberButton><span class="ml-1">%</span>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='position'">
                    <div class="ppitem" title="X轴">
                      <label>X轴</label>
                      <NumberButton class="nb" v-model="editFrame.rect.x" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                    </div>
                    <div class="ppitem" title="Y轴">
                      <label>Y轴</label>
                      <NumberButton class="nb" v-model="editFrame.rect.y" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='opacity'">
                    <div class="ppitem" title="透明度">
                      <label>透明度</label>
                      <NumberButton class="nb" v-model="editFrame.opacity" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="1" :wheelRatio="0.0001" :moveRatio="0.01" step="0.01" max="1" min="0"></NumberButton><span class="ml-1">%</span>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='rotate'">
                    <div class="ppitem" title="X轴">
                      <label>X轴</label>
                      <NumberButton class="nb" v-model="editFrame.rotate.x" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">度</span>
                    </div>
                    <div class="ppitem" title="Y轴">
                      <label>Y轴</label>
                      <NumberButton class="nb" v-model="editFrame.rotate.y" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">度</span>
                    </div>
                    <div class="ppitem" title="Z轴">
                      <label>Z轴</label>
                      <NumberButton class="nb" v-model="editFrame.rotate.z" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">度</span>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='font'">
                    <div class="ppitem" title="字号" style="display:flex">
                      <label>字号</label>
                      <NumberButton class="nb" v-model="editFrame.fontSize" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="16" :wheelRatio="0.01" :moveRatio="1" step="1" min="0"></NumberButton><span class="ml-1">像素</span>
                    </div>
                    <div class="ppitem" title="行高" style="display:flex">
                      <label>行高</label>
                      <NumberButton class="nb" v-model="editFrame.lineHeight" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="1.5" :wheelRatio="0.001" :moveRatio="0.1" step="0.1" max="99" min="0"></NumberButton><span class="ml-1">倍</span>
                    </div>
                    <div class="ppitem">
                      <label>对齐</label>
                      <button class="btn toorbarBtn" :class="{active:a.textAlign=='left'}" @click="a.textAlign='left';autoTourPlayer.renew();updateViewTextEditRect(a)">
                        <div><i class="fas fa-align-left"></i></div>
                        <div>居左</div>
                      </button>
                      <button class="btn toorbarBtn" :class="{active:a.textAlign=='center'}" @click="a.textAlign='center';autoTourPlayer.renew();updateViewTextEditRect(a)">
                        <div><i class="fas fa-align-center"></i></div>
                        <div>居中</div>
                      </button>
                      <button class="btn toorbarBtn" :class="{active:a.textAlign=='right'}" @click="a.textAlign='right';autoTourPlayer.renew();updateViewTextEditRect(a)">
                        <div><i class="fas fa-align-right"></i></div>
                        <div>居右</div>
                      </button>
                    </div>
                    <div style="display:inline-block;margin:0 10px;">
                      <label class="">整体对齐方向</label>
                      <div class="">
                        <template v-for="(edge,index) in directions">
                          <label class="checklaber" :class="{active:a.edge==edge}">
                            <input class="mycheck" type="radio" name="edge" v-model="a.edge" :value="edge" @change="autoTourPlayer.renew()" />
                          </label>
                          <br v-if="(index+1)%3==0" />
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='color'">
                    <div class="ppitem">
                      <label>字体颜色</label>
                      <ColorPicker v-model="editFrame.fontColor" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" />
                    </div>
                    <div class="ppitem">
                      <label>背景颜色</label>
                      <ColorPicker v-model="editFrame.bgColor" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" />
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='shadow'">
                    <div v-for="(ts,index) in editFrame.testShadows">
                      <template v-if="testShadowIndex==index">
                        <div class="ppitem">
                          <label style="width:50px">阴影颜色</label>
                          <ColorPicker v-model="ts.color" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" />
                        </div>
                        <div class="ppitem" title="X偏移量">
                          <label style="width:50px">X偏移量</label>
                          <NumberButton class="nb" v-model="ts.x" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="1"></NumberButton><span class="ml-1">像素</span>
                        </div>
                        <div class="ppitem" title="Y偏移量">
                          <label style="width:50px">Y偏移量</label>
                          <NumberButton class="nb" v-model="ts.y" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="1"></NumberButton><span class="ml-1">像素</span>
                        </div>
                        <div class="ppitem" title="模糊半径">
                          <label style="width:50px">模糊半径</label>
                          <NumberButton class="nb" v-model="ts.blurRadius" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" min="0" step="1"></NumberButton><span class="ml-1">像素</span>
                        </div>
                      </template>
                    </div>
                    <div class="shadowSelect" style="display:flex;">
                      <template v-for="(ts,index) in editFrame.testShadows">
                        <div class="shadowPreview" :class="{active:testShadowIndex==index}" :style="{color:editFrame.fontColor,'text-shadow':`${ts.x}px ${ts.y}px ${ts.blurRadius}px ${ts.color}`}" @click="testShadowIndex=index">阴影#{{index+1}}</div>
                      </template>
                      <div class="add" v-if="editFrame.testShadows.length<5" @click="addTextShadow()"><i class="fas fa-plus"></i></div>
                      <div class="add" v-if="testShadowIndex!=null" @click="delTextShadow(testShadowIndex)"><i class="fas fa-trash"></i></div>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='border'">
                    <div class="ppitem">
                      <label>颜色</label>
                      <ColorPicker v-model="editFrame.border.color" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" />
                    </div>
                    <div class="ppitem" title="宽度">
                      <label>宽度</label>
                      <NumberButton class="nb" v-model="editFrame.border.width" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">像素</span>
                    </div>
                    <div class="ppitem">
                      <label>样式</label>
                      <div class="overflow-x">
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='None'}" @click="editFrame.border.style='None';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style:none"></div>
                          <div>无</div>
                        </button>
                        <!--<button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Hidden'}" @click="editFrame.border.style='Hidden';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style:hidden" ></div>
                          <div>隐藏</div>
                        </button>-->
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Dotted'}" @click="editFrame.border.style='Dotted';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Dotted"></div>
                          <div>圆点</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Dashed'}" @click="editFrame.border.style='Dashed';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Dashed"></div>
                          <div>虚线</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Solid'}" @click="editFrame.border.style='Solid';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Solid"></div>
                          <div>实线</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Double'}" @click="editFrame.border.style='Double';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Double"></div>
                          <div>双线</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Groove'}" @click="editFrame.border.style='Groove';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Groove"></div>
                          <div>雕刻</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Ridge'}" @click="editFrame.border.style='Ridge';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Ridge"></div>
                          <div>浮雕</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Inset'}" @click="editFrame.border.style='Inset';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style: Inset"></div>
                          <div>凹陷</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:editFrame.border.style=='Outset'}" @click="editFrame.border.style='Outset';autoTourPlayer.renew();updateViewTextEditRect(a)">
                          <div class="borderStyleView" style="border-style:outset"></div>
                          <div>突出</div>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="imageProperty=='align'">
                  </template>
                  <template v-else>
                  </template>
                </template>
              </div>
              <div class="overflow-x">
                <div class="mx-2">
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='text'}" @click="imageProperty='text'">
                    <div><i class="fas fa-font"></i></div>
                    <div>内容</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='time'}" @click="imageProperty='time'">
                    <div><i class="fas fa-clock"></i></div>
                    <div>时间</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='font'}" @click="addUpdateFrame();imageProperty='font'">
                    <div><i class="fas fa-text-height"></i></div>
                    <div>字体</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='color'}" @click="addUpdateFrame();imageProperty='color'">
                    <div><i class="fas fa-palette"></i></div>
                    <div>颜色</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='size'}" @click="addUpdateFrame();imageProperty='size'">
                    <div><i class="fas fa-vector-square"></i></div>
                    <div>大小</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='position'}" @click="addUpdateFrame();imageProperty='position'">
                    <div><i class="fas fa-arrows-alt"></i></div>
                    <div>位置</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='opacity'}" @click="addUpdateFrame();imageProperty='opacity'">
                    <div><i class="fas fa-chess-board"></i></div>
                    <div>透明度</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='rotate'}" @click="addUpdateFrame();imageProperty='rotate'">
                    <div><i class="fas fa-sync"></i></div>
                    <div>旋转</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='shadow'}" @click="addUpdateFrame();imageProperty='shadow'">
                    <div><i class="fas fa-umbrella-beach"></i></div>
                    <div>阴影</div>
                  </button>
                  <button class="btn toorbarBtn" :class="{active:imageProperty=='border'}" @click="addUpdateFrame();imageProperty='border'">
                    <div><i class="fas fa-border-style"></i></div>
                    <div>边框</div>
                  </button>
                  <!--<button class="btn toorbarBtn" :class="{active:imageProperty=='align'}" @click="imageProperty='align'">
                    <div><i class="fas fa-align-left"></i></div>
                    <div>对齐</div>
                  </button>-->
                </div>
              </div>
            </div>
            <div v-if="toolbar=='imageEdit'" class="panel">
              <div style="display:flex;justify-content:space-between;align-items:baseline;">
                <div class="text-white" style="flex:auto">
                  <input type="text" ref="imageText" style="width:100%" class="form-control-sm dark" placeholder="输入文字" v-model="a.text" @change="">
                </div>
                <button class="btn panelBtn" @click="toolbar='image';imageProperty=null"><i class="fas fa-check"></i></button>
              </div>
              <template v-if="imageProperty=='time'">
                <div class="m-1">
                  <div class="ppitem" title="开始时间">
                    <label>开始时间</label>
                    <NumberButton class="nb" v-model="a.timeline" @change="autoTourPlayer.renew();timeSort()" :defaultValue="$refs.LineBar.getCenter()" :wheelRatio="1" :moveRatio="10" step="1" min="0"></NumberButton><span class="ml-1">ms</span>
                  </div>
                  <div class="ppitem" title="持续时间">
                    <label>持续时间</label>
                    <NumberButton class="nb" v-model="a.duration" @change="autoTourPlayer.renew();timeSort()" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0"></NumberButton><span class="ml-1">ms</span>
                  </div>
                  <template v-if="editFrame">
                    <div class="ppitem" title="关键帧">
                      <label style="width:4em">关键帧</label>
                      <NumberButton class="nb" v-model="editFrame.time" @change="autoTourPlayer.renew();" :defaultValue="5000" :wheelRatio="1" :moveRatio="10" step="1" :min="0" :max="a.duration"></NumberButton><span class="ml-1">ms</span>
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div>
                  <template v-if="editFrame">
                    <template v-if="imageProperty=='size'">
                      <div class="ppitem" title="宽度">
                        <label>宽度</label>
                        <NumberButton class="nb" v-model="editFrame.rect.width" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                      </div>
                      <div class="ppitem" title="高度">
                        <label>高度</label>
                        <NumberButton class="nb" v-model="editFrame.rect.height" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                      </div>
                      <div class="ppitem" title="缩放">
                        <label>缩放</label>
                        <NumberButton class="nb" v-model="editFrame.scale" @change="autoTourPlayer.renew();updateViewTextEditRect(a)" :defaultValue="1" :wheelRatio="-0.001" :moveRatio="0.01" step="0.001">{{(editFrame.scale*100).toFixed(1)}}</NumberButton><span class="ml-1">%</span>
                      </div>
                    </template>
                    <template v-else-if="imageProperty=='position'">
                      <div class="ppitem" title="X轴">
                        <label>X轴</label>
                        <NumberButton class="nb" v-model="editFrame.rect.x" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                      </div>
                      <div class="ppitem" title="Y轴">
                        <label>Y轴</label>
                        <NumberButton class="nb" v-model="editFrame.rect.y" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="0.1" step="0.01"></NumberButton><span class="ml-1">%</span>
                      </div>
                    </template>
                    <template v-else-if="imageProperty=='opacity'">
                      <div class="ppitem" title="混合模式">
                        <label>混合模式</label>
                        <select v-model="a.blendMode" @change="autoTourPlayer.renew();">
                          <option value="normal">正常</option>
                          <option value="multiply">正片叠底</option>
                          <option value="screen">滤色</option>
                          <option value="overlay">叠加</option>
                          <option value="darken">变暗</option>
                          <option value="lighten">变亮</option>
                          <option value="color-dodge">颜色减淡</option>
                          <option value="color-burn">颜色加深</option>
                          <option value="hard-light">强光</option>
                          <option value="soft-light">柔光</option>
                          <option value="difference">差值</option>
                          <option value="exclusion">排除</option>
                          <option value="hue">色相</option>
                          <option value="saturation">饱和度</option>
                          <option value="color">颜色</option>
                          <option value="luminosity">亮度</option>
                        </select>
                      </div>
                      <div class="ppitem" title="透明度">
                        <label>透明度</label>
                        <NumberButton class="nb" v-model="editFrame.opacity" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="1" :wheelRatio="0.0001" :moveRatio="0.01" step="0.01" max="1" min="0"></NumberButton><span class="ml-1">%</span>
                      </div>
                    </template>
                    <template v-else-if="imageProperty=='rotate'">
                      <div class="ppitem" title="X轴">
                        <label>X轴</label>
                        <NumberButton class="nb" v-model="editFrame.rotate.x" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">度</span>
                      </div>
                      <div class="ppitem" title="Y轴">
                        <label>Y轴</label>
                        <NumberButton class="nb" v-model="editFrame.rotate.y" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">度</span>
                      </div>
                      <div class="ppitem" title="Z轴">
                        <label>Z轴</label>
                        <NumberButton class="nb" v-model="editFrame.rotate.z" @change="autoTourPlayer.renew();updateImageViewEditRect(a)" :defaultValue="0" :wheelRatio="0.01" :moveRatio="1" step="1"></NumberButton><span class="ml-1">度</span>
                      </div>
                    </template>
                    <template v-else-if="imageProperty=='bgmode'">
                      <div class="ppitem" title="重复">
                        <label>重复</label>
                        <select v-model="editFrame.bgMode.repeat" @change="autoTourPlayer.renew();updateImageViewEditRect(a)">
                          <option value="no-repeat">不重复</option>
                          <option value="repeat">重复</option>
                          <option value="repeat-x">横向重复</option>
                          <option value="repeat-y">纵向重复</option>
                          <option value="space">间隔重复</option>
                          <option value="round">缩放重复</option>
                        </select>
                      </div>
                      <div class="ppitem" title="位置">
                        <label>位置</label>
                        <div class="input-group input-group-sm dropup" style="width:unset">
                          <input type="text" class="form-control" v-model="editFrame.bgMode.position" @change="autoTourPlayer.renew();updateImageViewEditRect(a)">
                          <div class="input-group-append">
                            <button class="btn btn-dark dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false"></button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.position='top'">顶部</a>
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.position='bottom'">底部</a>
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.position='left'">左边</a>
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.position='right'">右边</a>
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.position='center'">中央</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ppitem" title="尺寸">
                        <label>尺寸</label>
                        <div class="input-group input-group-sm dropup" style="width:unset">
                          <input type="text" class="form-control" v-model="editFrame.bgMode.size" @change="autoTourPlayer.renew();updateImageViewEditRect(a)">
                          <div class="input-group-append">
                            <button class="btn btn-dark dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false"></button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.size='cover'">完全覆盖</a>
                              <a class="dropdown-item" href="#" @click="editFrame.bgMode.size='contain'">完全装入</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                    </template>
                  </template>
                </div>
              </template>
              <div class="mx-2">
                <button class="btn toorbarBtn" @click="replaceFile()">
                  <div><i class="fas fa-retweet"></i></div>
                  <div>替换</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='time'}" @click="imageProperty='time'">
                  <div><i class="fas fa-clock"></i></div>
                  <div>时间</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='size'}" @click="addUpdateFrame();imageProperty='size'">
                  <div><i class="fas fa-vector-square"></i></div>
                  <div>大小</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='position'}" @click="addUpdateFrame();imageProperty='position'">
                  <div><i class="fas fa-arrows-alt"></i></div>
                  <div>位置</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='bgmode'}" @click="addUpdateFrame();imageProperty='bgmode'">
                  <div><i class="fas fa-fill-drip"></i></div>
                  <div>模式</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='opacity'}" @click="addUpdateFrame();imageProperty='opacity'">
                  <div><i class="fas fa-chess-board"></i></div>
                  <div>透明度</div>
                </button>
                <button class="btn toorbarBtn" :class="{active:imageProperty=='rotate'}" @click="addUpdateFrame();imageProperty='rotate'">
                  <div><i class="fas fa-sync"></i></div>
                  <div>旋转</div>
                </button>
              </div>
            </div>
            <div v-if="toolbar=='hotspotEdit'" class="panel">
              <template v-for="(item,index) in [a.hotspot]">
                <div style="display:flex;justify-content:space-between;align-items:baseline;">
                  <div class="text-white" style="flex:auto">
                    <input type="text" ref="hotspotTitle" style="width:100%" class="form-control-sm dark" placeholder="输入文字" v-model="item.title" @change="setHotspot(item)">
                  </div>
                  <button class="btn panelBtn" @click="toolbar='hotspot'"><i class="fas fa-check"></i></button>
                </div>
                <div style="padding:5px">
                  <template v-if="editFrame">

                    <template v-if="item.type=='Image'">
                      <div class="text-shadow" style="pointer-events:none;font-size:12px;"></div>
                      <div v-if="showRange=='scale'" class="">
                        <div class="ppitem" title="缩放">
                          <label>缩放</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.scale" @change="setHotspot(item)" :defaultValue="0.5" :wheelRatio="0.0001" :moveRatio="0.01" step="0.01" :min="0" :max="10"></NumberButton><span class="ml-1">%</span>
                        </div>
                      </div>
                      <div v-if="showRange=='rotate'" class="">
                        <div class="ppitem" title="旋转">
                          <label>旋转</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.rotate" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                      </div>
                      <div v-if="showRange=='rotation'&&item.distorted" class="">
                        <div class="ppitem" title="X轴">
                          <label>X轴</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.rx" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                        <div class="ppitem" title="Y轴">
                          <label>Y轴</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.ry" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                        <div class="ppitem" title="Z轴">
                          <label>Z轴</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.rz" @change="setHotspot(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                      </div>
                      <div>
                        <button class="btn toorbarBtn" @click="item.showTitle=!item.showTitle;setHotspot(item)">
                          <div>
                            <i v-if="item.showTitle" class="fas fa-toggle-on"></i>
                            <i v-else class="fas fa-toggle-off"></i>
                          </div>
                          <div>显示名称</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:showRange=='scale'}" @click="showRange=='scale'?showRange=null:showRange='scale'">
                          <div>{{editFrame.hotspotView.scale}}</div>
                          <div>缩放</div>
                        </button>
                        <button class="btn toorbarBtn" :class="{active:showRange=='rotate'}" @click="showRange=='rotate'?showRange=null:showRange='rotate'">
                          <div>{{editFrame.hotspotView.rotate}}</div>
                          <div>旋转</div>
                        </button>
                        <button class="btn toorbarBtn" @click="item.distorted=!item.distorted;setHotspot(item)">
                          <div>
                            <i v-if="item.distorted" class="fas fa-toggle-on"></i>
                            <i v-else class="fas fa-toggle-off"></i>
                          </div>
                          <div>扭曲</div>
                        </button>
                        <button v-if="item.distorted" class="btn toorbarBtn" :class="{active:showRange=='rotation'}" @click="showRange=='rotation'?showRange=null:showRange='rotation'">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 width="12" height="12"
                                 viewBox="0 0 172 172"
                                 style=" fill:#000000;">
                              <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M85.88802,21.40202c-3.95253,0.06178 -7.10881,3.312 -7.05469,7.26465v60.52474l-53.95996,33.73373c-2.28126,1.30356 -3.66641,3.75083 -3.60974,6.37765c0.05667,2.62682 1.54608,5.01208 3.88144,6.21603c2.33536,1.20396 5.14227,1.0336 7.31489,-0.44395l53.54004,-33.45377l53.54004,33.45377c2.17261,1.47755 4.97953,1.6479 7.31488,0.44394c2.33535,-1.20395 3.82477,-3.58921 3.88144,-6.21603c0.05667,-2.62682 -1.32848,-5.07409 -3.60973,-6.37765l-53.95996,-33.73373v-60.52474c0.02653,-1.93715 -0.73227,-3.80254 -2.10349,-5.17112c-1.37122,-1.36858 -3.23806,-2.12378 -5.17516,-2.09353z"></path></g></g>
                            </svg>
                          </div>
                          <div>三维旋转</div>
                        </button>
                        <button v-else class="btn toorbarBtn" @click="item.zoom=!item.zoom;setHotspot(item)">
                          <div>
                            <i v-if="item.zoom" class="fas fa-toggle-on"></i>
                            <i v-else class="fas fa-toggle-off"></i>
                          </div>
                          <div>跟随缩放</div>
                        </button>
                        <button class="btn toorbarBtn" @click="showHotspotIconLibDialog(item.guid)">
                          <div><i class="fas fa-retweet"></i></div>
                          <div>替换</div>
                        </button>
                      </div>
                    </template>
                    <template v-if="item.type=='Video'">
                      <div class="text-shadow" style="pointer-events:none;font-size:12px;">当前</div>
                      <div v-if="showRange=='scale'" class="">
                        <div class="ppitem" title="缩放">
                          <label>缩放</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.scale" @change="setVideo(item)" :defaultValue="0.5" :wheelRatio="0.0001" :moveRatio="0.01" step="0.01" :min="0.01" :max="2"></NumberButton>
                        </div>
                      </div>
                      <div v-if="showRange=='rotate'" class="">
                        <div class="ppitem" title="旋转">
                          <label>旋转</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.rotate" @change="setVideo(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton>
                        </div>
                      </div>
                      <div v-if="showRange=='chromakey'" class="rangeBar">
                        <div class="mb-1">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <label for="" class="input-group-text">抠像颜色</label>
                            </div>
                            <input class="form-control" type="color" placeholder="抠像颜色" v-model="editFrame.hotspotView.chromakeyColor" @change="setVideo(item)" @input="setVideo(item)" />
                            <div class="input-group-prepend">
                              <span class="input-group-text" @click="getColor(item)"><i class="fa fa-eye-dropper"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="ppitem" title="范围">
                          <label>范围</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.threshold" @change="setVideo(item)" :defaultValue="0.01" :wheelRatio="0.0005" :moveRatio="0.001" step="0.001" :min="0" :max="1"></NumberButton><span class="ml-1">像素</span>
                        </div>
                        <div class="ppitem" title="模糊">
                          <label>模糊</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.smoothing" @change="setVideo(item)" :defaultValue="0.01" :wheelRatio="0.0005" :moveRatio="0.001" step="0.001" :min="0" :max="1"></NumberButton><span class="ml-1">像素</span>
                        </div>
                      </div>
                      <div class="text-right">
                        <div class="btn-group btn-group-sm" role="group" style="width:100%">
                          <button type="button" class="btn" :class="{active:showRange=='scale','btn-default':showRange!='scale'}" @click="showRange=='scale'?showRange=null:showRange='scale'" @dblclick="item.scale=0.5;setVideo(item)">缩放：{{item.scale}}</button>
                          <button type="button" class="btn" :class="{active:showRange=='rotate','btn-default':showRange!='rotate'}" @click="showRange=='rotate'?showRange=null:showRange='rotate'" @dblclick="item.rotate=0;setVideo(item)">旋转：{{item.rotate}}°</button>
                          <button type="button" class="btn" :class="{active:showRange=='chromakey','btn-warning':showRange!='chromakey'}" @click="showRange=='chromakey'?showRange=null:showRange='chromakey'">抠像</button>
                          <button type="button" class="btn btn-danger" @click="showDeleteHotspot()">删除</button>
                        </div>
                      </div>
                      <div v-if="showRange=='rotation'&&item.distorted" class="rangeBar">
                        <div class="ppitem" title="X轴">
                          <label>X轴</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.rx" @change="setVideo(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                        <div class="ppitem" title="Y轴">
                          <label>Y轴</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.ry" @change="setVideo(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                        <div class="ppitem" title="Z轴">
                          <label>Z轴</label>
                          <NumberButton class="nb" v-model="editFrame.hotspotView.rz" @change="setVideo(item)" :defaultValue="0" :wheelRatio="0.1" :moveRatio="1" step="1" :min="-180" :max="180"></NumberButton><span class="ml-1">度</span>
                        </div>
                      </div>
                      <div class="text-right">
                        <div class="btn-group btn-group-sm" role="group">
                          <button type="button" class="btn btn-default" @click="item.distorted=!item.distorted;setVideo(item)">扭曲：{{item.distorted?'是':'否'}}</button>
                          <button v-if="item.distorted" type="button" class="btn" :class="{'btn-primary':showRange=='rotation','btn-default':showRange!='rotation'}" @click="showRange=='rotation'?showRange=null:showRange='rotation'">三维旋转</button>
                          <button v-else type="button" class="btn btn-default" @click="item.zoom=!item.zoom;setVideo(item)" :disabled="item.distorted">跟随缩放：{{item.zoom?'是':'否'}}</button>
                          <button type="button" class="btn btn-default" @click="item.loop=!item.loop;setVideo(item)">循环播放：{{item.loop?'是':'否'}}</button>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
      <div v-else-if="autoTourPlayer" style="pointer-events: auto; display: flex; padding: 0.5em; align-items: baseline; width: 100%;">
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-if="autoTourPlayer.state!='play'" @click="autoTourPlayer.play"><i class="fas fa-play"></i></a>
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-else @click="autoTourPlayer.pause"><i class="fas fa-pause"></i></a>
        <span class="text-shadow" style="flex:none;font-size:12px;">{{moment(autoTourPlayer.currentTime).format('mm:ss')}}</span>
        <input type="range" class="" min="0" :max="autoTourPlayer.totalDuration" v-model="autoTourPlayer.currentTime" style="flex:auto;margin:auto 5px;" />
        <span class="text-shadow" style="flex:none;font-size:12px;">{{moment(autoTourPlayer.totalDuration).format('mm:ss')}}</span>
        <!--<a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" @click="autoTourPlayer.stop"><i class="fas fa-stop"></i></a>-->
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0 0 0.5em;line-height:12px" @click="goExpend"><i class="fas fa-chevron-up"></i></a>
      </div>
      <div class="rectEdit" v-if="editRect">
        <DragingEvent class="rectEditBox" :style="rectStyle()">
          <!--:stop="true" @moveing="rectMoveing($event,'ct')">-->
          <DragingEvent class="hideBtn text-shadow" :stop="true" @click="clearEditRect"><i class="fa fa-check"></i></DragingEvent>
          <DragingEvent class="moveBtn text-shadow" :stop="true" @moveing="rectMoveingAll($event)"><i class="fa fa-arrows-alt"></i></DragingEvent>
          <DragingEvent class="dragBtn lt" :stop="true" @moveing="rectMoveing($event,'lt')"></DragingEvent>
          <DragingEvent class="dragBtn tm" :stop="true" @moveing="rectMoveing($event,'tm')"></DragingEvent>
          <DragingEvent class="dragBtn rt" :stop="true" @moveing="rectMoveing($event,'rt')"></DragingEvent>
          <DragingEvent class="dragBtn lm" :stop="true" @moveing="rectMoveing($event,'lm')"></DragingEvent>
          <DragingEvent class="dragBtn ct" :stop="true" @moveing="rectMoveing($event,'ct')"></DragingEvent>
          <DragingEvent class="dragBtn rm" :stop="true" @moveing="rectMoveing($event,'rm')"></DragingEvent>
          <DragingEvent class="dragBtn lb" :stop="true" @moveing="rectMoveing($event,'lb')"></DragingEvent>
          <DragingEvent class="dragBtn bm" :stop="true" @moveing="rectMoveing($event,'bm')"></DragingEvent>
          <DragingEvent class="dragBtn rb" :stop="true" @moveing="rectMoveing($event,'rb')"></DragingEvent>
        </DragingEvent>
      </div>
      <div style="pointer-events:auto">
        <HotspotIconLib ref="HotspotIconLib" :libUrl="urls.hotspotIcons()" :params="params" :onSelected="HotspotIconLibSelected"></HotspotIconLib>
        <FileLib ref="FileLib" :withType="fileType" :params="params" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" @submit="FileLibSelected"></FileLib>
      </div>
    </div>
  </client-only>
</template>
<script>
  import moment from 'moment'
  import { uuid } from 'vue-uuid'
  import {
    ViewImage,
    ViewText,
    SetHotspot,
    SetView,
  } from '../../../libs/autoTourActs'
  import keyFrames from '../../../libs/keyFrames'
  import PellEditor from '../../PellEditor'
  import FileLib from '../../FileLib'
  import LineBar from '../../LineBar'
  import NumberButton from '../../NumberButton'
  import HotspotIconLib from '../../HotspotIconLib'
  import TourGroups from './EditTourGroupsView'
  import TourGroupItems from './EditTourGroupItemsView'
  import KeyFrames from '../../KeyFrames'
  import DragingEvent from '../../DragingEvent'
  import ColorPicker from '../../ColorPicker'
  import AutoTextarea from '../../AutoTextarea'
  export default {
    components: {
      PellEditor,
      FileLib,
      HotspotIconLib,
      LineBar,
      TourGroups,
      TourGroupItems,
      NumberButton,
      KeyFrames,
      DragingEvent,
      ColorPicker,
      AutoTextarea,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        autoTour: this.newAutoTour(),
        editIndex: null,
        oldTime: null,
        act: this.newAct(),
        fileIndex: null,
        fileTime: null,
        fileType: null,
        showRange: null,
        hotspots: [],
        actList: {
          setView: 'setView',
          playAudio: 'playAudio',
        },
        audioEnd: null,
        hide: true,
        expend: true,
        actName: {
          'setView': '视图',
          'playAudio': '音频',
          'setHotspot': '标注',
          'subtitle': '字幕',
          'viewText': '文字',
          'viewImage': '图片',
        },
        moment: moment,
        toolbar: null,
        imageProperty: null,
        dragDelay: 300,
        dragDelayTimer: null,
        draging: false,
        dragD: null,
        frameIndex: null,
        testShadowIndex: null,
        editRect: false,
        editFrame: null,
        previousFrame: null,
        previousFrameIndex: null,
        rectData: null,
        directions: [
          'lefttop', 'top', 'righttop', 'left', 'center', 'right', 'leftbottom', 'bottom', 'rightbottom'
        ],
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      autoTourPlayer() {
        return this.publicData.autoTourPlayer
      },
      //autoTour() {
      //  return this.tour.autoTour
      //},
      senceGuid() {
        return this.publicData.editItemGuid
      },
      pano() {
        return this.publicData.pano || {}
      },
      tour() {
        return this.publicData.form || {}
      },
      firstViewRow() {
        return this.getRowByType('view')
      }
    },
    watch: {
      tour(val, oldval) {
        if (val.autoTour) {
          this.autoTour = val.autoTour
        } else {
          this.autoTour = this.newAutoTour()
        }
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          hotspotClick: this.hotspotClick,
          hotspotMove: this.hotspotMove,
          dragHotspot: this.dragHotspot,
          autoTourPlay: this.autoTourPlay,
        },
        events: {
          change: {
            target: "Data",
            name: "changeAutoTour"
          },
        }
      })
      //if (!this.autoTour) {
      //  this.publicData.form.autoTour = this.newAutoTour()
      //}
      setTimeout(() => {
        this.$refs.LineBar.renewViewData()
        this.autoTourPlayer.onTimeChange(this.updateTime)
      }, 100)
      if (!this.publicData.form.autoTour) {
        this.publicData.form.autoTour = this.autoTour
        this.getFunc({ target: 'AutoTour', name: 'setAutoTour' })()

      } else {
        this.autoTour = this.publicData.form.autoTour
        if (!this.autoTour.rows) {
          this.autoTour.rows = this.newRows()
        }
      }
    },
    destroyed() {
    },
    methods: {
      goExpend() {
        this.expend = true;
        setTimeout(() => {
          this.updateTime(this.autoTourPlayer.currentTime)
        })
      },
      addTextShadow() {
        var i = this.editFrame.testShadows.push({ inset: false, x: 1, y: 1, blurRadius: 0, spreadRadius: 0, color: '#000000ff' })
        this.testShadowIndex = i - 1
      },
      delTextShadow(index) {
        this.editFrame.testShadows.splice(index, 1)
        this.autoTourPlayer.renew()
        this.testShadowIndex = null
      },
      setEditRect(data, callback) {
        this.rectData = data
        this.editRect = true
        this.rectChange = callback
      },
      rectMoveing({ start, last, now }, type) {
        var vw = window.screen.width
        var vh = window.screen.height
        var dx = ((now.x - last.x) / vw) * 100
        var dy = ((now.y - last.y) / vh) * 100
        console.log({ vw, vh, dx, dy, start, last, now, })
        this.rectData
        switch (type) {
          case 'lt':
            this.rectData.width -= dx
            this.rectData.height -= dy
            this.rectData.x -= dx / 2
            this.rectData.y -= dy / 2
            break
          case 'tm':
            //this.rectData.width -= dx
            this.rectData.height -= dy
            //this.rectData.x -= dx / 2
            this.rectData.y -= dy / 2
            break
          case 'rt':
            this.rectData.width += dx
            this.rectData.height -= dy
            this.rectData.x -= dx / 2
            this.rectData.y -= dy / 2
            break
          case 'lm':
            this.rectData.width -= dx
            //this.rectData.height -= dy
            this.rectData.x -= dx / 2
            //this.rectData.y -= dy / 2
            break
          case 'ct':
            this.rectData.x -= dx
            this.rectData.y -= dy
            break
          case 'rm':
            this.rectData.width += dx
            //this.rectData.height -= dy
            this.rectData.x -= dx / 2
            //this.rectData.y -= dy / 2
            break
          case 'lb':
            this.rectData.width -= dx
            this.rectData.height += dy
            this.rectData.x -= dx / 2
            this.rectData.y -= dy / 2
            break
          case 'bm':
            //this.rectData.width -= dx
            this.rectData.height += dy
            //this.rectData.x -= dx / 2
            this.rectData.y -= dy / 2
            break
          case 'rb':
            this.rectData.width += dx
            this.rectData.height += dy
            this.rectData.x -= dx / 2
            this.rectData.y -= dy / 2
            break
          default:
        }
        //if (this.rectData.height < 10) {
        //  this.rectData.height = 10
        //}
        //if (this.rectData.width < 10) {
        //  this.rectData.width = 10
        //}
        this.rectMove()
      },
      rectMoveingAll({ start, last, now }) {
        var vw = window.screen.width
        var vh = window.screen.height
        var dx = ((now.x - last.x) / vw) * 100
        var dy = ((now.y - last.y) / vh) * 100
        console.log('rectMoveingAll', dx, dy)
        var act = this.autoTour.acts[this.editIndex]
        for (var i in act.keyFrames) {
          var f = act.keyFrames[i]
          f.rect.x -= dx
          f.rect.y -= dy
        }
        this.updateImageViewEditRect(act)
        this.autoTourPlayer.renew()
      },
      clearEditRect() {
        this.rectData = null
        this.editRect = false
      },
      rectMove() {
        this.rectChange(this.rectData)
      },
      rectStyle() {
        var style = {
          position: 'absolute',
          left: '50vw',
          top: '50vh',
          width: '80vw',
          height: '80vh',
        }
        var rect = this.rectData
        if (rect) {
          var w = rect.width
          var h = rect.height
          style.left = `${50 - rect.x - (w / 2)}vw`
          style.top = `${50 - rect.y - (h / 2)}vh`
          style.width = `${w}vw`
          style.height = `${h}vh`
        }
        return style
      },
      rowToRowSetting(settings, type) {
        var r = []
        for (var i in settings) {
          r.push(this.newRowSetting(settings[i]))
        }
        return r
      },
      newRowSetting(obj) {
        return {
          ...obj
        }
      },
      callKrpano(name, args) {
        var func = this.getFunc({
          target: "FullPano",
          name: "callKrpano"
        })
        console.log(func, { name, args })
        func({ name, args })
      },
      lineBarItemClass(prop, editIndex) {
        var c = { active: this.getIndex(prop.data) == editIndex, draging: prop.draging }
        c[prop.dragType] = true
        c[prop.data.act] = true
        return c
      },
      updateTime(t) {
        var time = Number(Number(t).toFixed(0))
        var lb = this.$refs.LineBar
        if (!lb) return
        var w = (lb.end - lb.start) / 2
        lb.start = time - w
        lb.end = time + w
      },
      setTime(t) {
        var time = Number(Number(t).toFixed(0))

        var lb = this.$refs.LineBar
        var w = (lb.end - lb.start) / 2
        lb.start = time - w
        lb.end = time + w
        this.autoTourPlayer.currentTime = time
      },
      timeChange(start, end) {
        var time = (start + end) / 2
        console.log(time)
        this.autoTourPlayer.currentTime = time
        this.checkEditFrame(time)
      },
      checkEditFrame(time) {
        if (time == null) {
          time = this.$refs.LineBar.getCenter()
        }
        if (this.editIndex != null) {
          var R = this.$refs.LineBar.getRatio()
          var limit = R * 7
          var a = this.autoTour.acts[this.editIndex]
          var dt = limit + 1
          var f = null
          var fi = null
          var nf = null
          var nfi = null
          for (var i in a.keyFrames) {
            var kf = a.keyFrames[i]
            var d = Math.abs(kf.time - (time - a.timeline))
            if (d < limit && d < dt) {
              f = kf
              fi = i
            }
            if (kf.time < time - a.timeline) {
              nf = kf
              nfi = i
            }
          }
          console.log('frameIndex', fi)
          this.frameIndex = fi
          this.editFrame = f
          this.previousFrame = nf
          this.previousFrameIndex = nfi

          switch (a.act) {
            case 'viewImage':
              this.updateImageViewEditRect(a)
              break
            case 'viewText':
              this.updateViewTextEditRect(a)
              break
            case 'pausePoint':
              this.frameIndex = 0
              this.editFrame = a.keyFrames[0]
              this.updatePausePointEditRect(a)
              break
            default:
          }
        }

      },
      beforeDrag(l) {
        return this.getIndex(l.data) == this.editIndex
      },
      canDrop(l, r) {
        switch (r.setting.type) {
          case 'view':
            return l.data.act == 'setView'
          case 'audio':
            return l.data.act == 'playAudio'
          case 'addon':
            switch (l.data.act) {
              case 'viewImage':
              case 'viewText':
              case 'subtitle':
              case 'setHotspot':
                return true;
              case 'setView':
              case 'playAudio':
              default:
                return false;
            }
          default:
            console.error(`type err ${r.setting.type}`)
            return false;
        }
        l.data.act == r.setting.type
        return this.getIndex(l.data) == this.editIndex
      },
      getRowTypeByAct(act) {
        switch (act) {
          case 'setView':
            return 'view'
          case 'playAudio':
            return 'audio'
          case 'subtitle':
          case 'viewText':
          case 'setHotspot':
            return 'addon'
          default:
            return 'addon'
        }
      },
      stop() {
      },
      dragstart(start, data, startDrag, type) {
        var px = start.x
        var py = start.y
        this.dragDelayTimer = setTimeout(() => {
          startDrag(data, px, py, type)
          this.draging = true
          this.dragDelayTimer = null
        }, this.dragDelay)
        this.dragD = 0
      },
      drag({ start, last, now }, data, Drag, type) {
        var px = now.x
        var py = now.y
        var mx = now.x - last.x
        var my = now.y - last.y
        this.dragD += Math.sqrt((mx * mx) + (my * my))
        if (this.dragD > 10 && this.dragDelayTimer) {
          clearTimeout(this.dragDelayTimer)
          this.dragDelayTimer = null
        }
        if (this.draging) {
          Drag(px, py, type)
        }
      },
      dragEnd({ start, last, now }, data, DragEnd, type) {
        clearTimeout(this.dragDelayTimer)
        this.dragDelayTimer = null
        this.draging = false
        console.log('dragEnd', DragEnd)
        DragEnd()
      },
      itemSelected(item) {
        var i = this.getIndex(item)
        this.edit(i)
        console.log(i, item)
      },
      itemMoved(item, l, r, type) {
        console.log('itemMoved', item, l, r)
        var oldTimeLine = item.timeline
        item.timeline = Number(l.start.toFixed(0))
        item.duration = Number((l.end - l.start).toFixed(0))
        if ((item.act == 'playAudio' || (item.act == 'setView' && item.startTime != undefined)) && type == 'left') {
          item.startTime = item.startTime + Number(l.start.toFixed(0)) - oldTimeLine
          if (item.startTime < 0) {
            var d = -item.startTime
            item.startTime += d
            item.timeline += d
          }
          var audioLength = moment.duration(item.file.duration).asMilliseconds()
          console.log(audioLength)
          if (item.duration > audioLength) {
            item.duration = audioLength
          }
        }
        if ((item.act == 'playAudio' || (item.act == 'setView' && item.startTime != undefined)) && type == 'right') {
          var audioLength = moment.duration(item.file.duration).asMilliseconds()
          console.log(audioLength)
          if (item.duration > audioLength) {
            item.duration = audioLength
          }
        }
        if (r && this.canDrop(l, r)) {
          console.log('dropable')
          //item.rowGuid = r.setting.guid
          var rowItems = this.getRowItems(r.setting)
          if (!this.hasOverlap(rowItems, item)) {
            console.log('set 1', rowItems, item, r)
            item.rowGuid = r.setting.guid
          } else {
            var rowType = this.getRowTypeByAct(item.act)
            var targetRows = this.getRowsByType(rowType)
            var hasAbleRow = false
            for (var i in targetRows) {
              var rowItems2 = this.getRowItems(targetRows[i])
              if (!this.hasOverlap(rowItems2, item)) {
                hasAbleRow = true
                console.log('set 2')
                item.rowGuid = targetRows[i].guid
              }
            }
            if (!hasAbleRow) {
              var newrow = this.newRow({ name: `新轨道`, type: rowType })
              console.log('set 3')
              item.rowGuid = newrow.guid
              this.autoTour.rows.push(newrow)
              this.sortRows()
            }
          }
        }
        this.timeSort()
        //this.change()
      },
      getRowItems(row) {
        var items = []
        for (var i in this.autoTour.acts) {
          var a = this.autoTour.acts[i]
          if (a.rowGuid == row.guid) {
            items.push(a)
          }
        }
        return items
      },
      hasOverlap(values, item) {
        for (var i in values) {
          var v = values[i]
          if (v != item && v.timeline < item.timeline + item.duration && v.timeline + v.duration > item.timeline) {
            return true
          }
        }
        return false
      },
      sortRows() {
        var types = { view: 0, audio: 1, addon: 2 }
        this.autoTour.rows.sort((a, b) => types[a.type] - types[b.type])
      },
      getIndex(item) {
        for (var i in this.autoTour.acts) {
          if (this.autoTour.acts[i] == item) {
            return i
          }
        }
        return null
      },
      dragHotspot(item) {
        console.log(item)
        for (var i in this.hotspots) {
          if (item.guid == this.hotspots[i].guid) {
            //if (this.autoTour.acts[this.editIndex].inheritView == true && item.title == '起点') {
            //  return
            //}
            this.getFunc({
              target: 'FullPano',
              name: 'dragHotspot'
            })(item)
            return
          }
        }
        if (this.editIndex != null) {
          var editAct = this.autoTour.acts[this.editIndex]
          console.log('setHotspot dragHotspot', editAct.act == 'setHotspot', editAct.hotspot.guid, item.guid)
          if (editAct.act == 'setHotspot' && editAct.hotspot.guid == item.guid) {
            this.getFunc({
              target: 'FullPano',
              name: 'dragHotspot'
            })(item)
            return
          }
        }
      },
      hotspotMove({ item, ath, atv }) {
        console.log('EAhotspotMove', item, ath, atv)
        switch (this.autoTour.acts[this.editIndex].act) {
          case 'subtitle':
            break
          case 'viewText':
            break
          case 'setHotspot':
            console.log('setHotspot Move')
            this.addUpdateFrame()
            this.editFrame.hotspotView.ath = ath
            this.editFrame.hotspotView.atv = atv
            break
          case 'setView':
            if (item.title == '起点') {
              console.log('起点move')
              var v = this.autoTour.acts[this.editIndex].startView
              v.vLookAt = atv
              v.hLookAt = ath
              var l = this.hotspots[0]
              l.points[0].atv = atv
              l.points[0].ath = ath
              console.log(l)
              this.getFunc({
                target: 'FullPano',
                name: 'setPolygonal'
              })({ ...l })
            } else if (item.title == '终点') {
              var v = this.autoTour.acts[this.editIndex].view
              v.vLookAt = atv
              v.hLookAt = ath
              var l = this.hotspots[0]
              l.points[1].atv = atv
              l.points[1].ath = ath
              this.getFunc({
                target: 'FullPano',
                name: 'setPolygonal'
              })({ ...l })
            }
            break
          default:
        }
      },
      setHotspot(a) {
        this.renewTime()
        //var h = { ...a.hotspot, guid: uuid.v1() }
        //this.hotspots.push(h)
        //this.getFunc({
        //  target: 'FullPano',
        //  name: 'setHotspot'
        //})({ ...h })
      },
      renewTime() {
        this.autoTourPlayer.setTime(this.autoTourPlayer.currentTime)
      },
      removeHotspots() {
        for (var i in this.hotspots) {
          this.getFunc({
            target: 'FullPano',
            name: 'removeHotspot'
          })(this.hotspots[i])
        }
        this.hotspots = []
      },
      hotspotClick(h) {
        console.log(h)
      },
      autoTourPlay() {
        this.edit(null)
      },
      edit(index) {
        console.log(index, this.editIndex)
        if (this.editIndex != null) {
          var a1 = this.autoTour.acts[this.editIndex]
          switch (a1.act) {
            case 'setView':
              this.removeHotspots()
              break
            case 'playAudio':
              break
            case 'subtitle':
              break
            case 'setHotspot':
              this.removeHotspots()
              break
            case 'viewImage':
              this.clearEditRect()
              break
            case 'viewText':
              this.clearEditRect()
              break
            case 'pausePoint':
              break
            default:
          }
        }
        if (index != null) {
          console.log(this.autoTourPlayer)
          //if (this.autoTourPlayer) {
          //  this.autoTourPlayer.stop()
          //}
          var a2 = this.autoTour.acts[index]
          switch (a2.act) {
            case 'setView':
              console.log(a2)
              this.toolbar = 'view'
              //this.lookAt(a2.startView, a2.senceGuid)
              //setTimeout(() => { this.setViewLine(a2.startView, a2.view) })
              break
            case 'playAudio':
              this.toolbar = 'audio'
              break
            case 'subtitle':
              this.toolbar = 'subtitle'
              break
            case 'setHotspot':
              this.toolbar = 'hotspot'
              //this.lookAt({ hLookAt: a2.hotspot.ath, vLookAt: a2.hotspot.atv, }, a2.senceGuid)
              setTimeout(() => { this.setHotspot(a2) })
              break
            case 'viewImage':
              this.toolbar = 'image'
              this.updateImageViewEditRect(a2)
              break
            case 'viewText':
              this.toolbar = 'viewText'
              this.updateViewTextEditRect(a2)
              break
            case 'pausePoint':
              this.toolbar = 'points'
              break
            default:
          }
        } else {
          this.toolbar = null
        }
        this.editIndex = index
        this.frameIndex = null
        this.editFrame = null
      },
      getFrame() {
        if (this.editIndex) {
          var act = this.autoTour.acts[this.editIndex]
          if (this.frameIndex) {
            return act.keyFrames[this.frameIndex]
          } else {
            var frame
            switch (act.act) {
              case 'setView':
                break
              case 'playAudio':
                break
              case 'subtitle':
                break
              case 'viewText':
                break
              case 'setHotspot':
                break
              case 'viewImage':
                var f = new keyFrames(a.keyFrames, ViewImage.DefaultViewImageFrame, a.duration, null, null, ViewImage.getIntermediateFrame)
                frame = f.getFrame(this.autoTourPlayer.currentTime)
                this.editFrame = f
                break
              default:
            }
            return frame
          }
        }
        return null
      },
      frameChanged() {
        if (this.editIndex) {
          var act = this.autoTour.acts[this.editIndex]
          this.updateImageViewEditRect(act)
          if (this.frameIndex) {

          } else {
            switch (act.act) {
              case 'setView':
                break
              case 'playAudio':
                break
              case 'subtitle':
                break
              case 'viewText':
                break
              case 'setHotspot':
                break
              case 'viewImage':
                break
              default:
            }
          }
        }
      },
      updateImageViewEditRect(a) {
        var f = new keyFrames(a.keyFrames, ViewImage.DefaultViewImageFrame, a.duration, null, null, ViewImage.getIntermediateFrame)
        var nf = f.getFrame(this.autoTourPlayer.currentTime - a.timeline)
        //this.editFrame = nf
        var rect = nf.rect || ViewImage.DefaultViewImageFrame.rect
        this.setEditRect(rect, (newRect) => {
          if (this.frameIndex != null) {
            var act = this.autoTour.acts[this.editIndex]
            var frame = act.keyFrames[this.frameIndex]
            frame.time = Number(frame.time.toFixed(0))
            frame.rect = { ...newRect }
            for (var i in frame.rect) {
              frame.rect[i] = Number(frame.rect[i].toFixed(2))
            }
          } else {
            this.addFrameTime(this.$refs.LineBar.getCenter(), { rect: { ...newRect } })
          }
          this.autoTourPlayer.renew()
        })
      },
      updateViewTextEditRect(a) {
        var f = new keyFrames(a.keyFrames, ViewText.DefaultViewTextFrame, a.duration, null, null, ViewText.getIntermediateFrame)
        var nf = f.getFrame(this.autoTourPlayer.currentTime - a.timeline)
        //this.editFrame = nf
        var rect = nf.rect || ViewText.DefaultViewTextFrame.rect
        this.setEditRect(rect, (newRect) => {
          if (this.frameIndex != null) {
            var act = this.autoTour.acts[this.editIndex]
            var frame = act.keyFrames[this.frameIndex]
            frame.rect = { ...newRect }
            for (var i in frame.rect) {
              frame.rect[i] = Number(frame.rect[i].toFixed(2))
            }
          } else {
            this.addFrameTime(this.$refs.LineBar.getCenter(), { rect: { ...newRect } })
          }
          this.autoTourPlayer.renew()
        })
      },
      updatePausePointEditRect(a) {
        var nf = a.keyFrames[0] || {}
        //this.editFrame = nf
        var rect = nf.rect || {}
        this.setEditRect(rect, (newRect) => {
          if (this.frameIndex != null) {
            var act = this.autoTour.acts[this.editIndex]
            var frame = act.keyFrames[this.frameIndex]
            frame.rect = { ...newRect }
            for (var i in frame.rect) {
              frame.rect[i] = Number(frame.rect[i].toFixed(2))
            }
          } else {
            this.addFrameTime(this.$refs.LineBar.getCenter(), { rect: { ...newRect } })
          }
          this.autoTourPlayer.renew()
        })
      },
      addUpdateFrame() {
        if (this.frameIndex == null) {
          this.addFrameTime(this.$refs.LineBar.getCenter())
        } else {
          console.log('updateFrame')
          var act = this.autoTour.acts[this.editIndex]
          var f = act.keyFrames[this.frameIndex]
          var nf = this.getNewFrame({ act: act.act, keyFrames: [] }, f.time + act.timeline)
          for (var i in nf) {
            console.log('updateFrame', f[i] == null)
            if (f[i] == null && nf[i] != null) {
              console.log('updateFrame', i)
              f[i] = nf[i]
            }
          }
        }
      },
      timeFocus(t) {
        var time = Number(Number(t).toFixed(0))

        this.oldTime = Number(time)
      },
      //setViewLine(v1, v2) {
      //  var line = {
      //    guid: uuid.v1(),
      //    points: [
      //      {
      //        atv: v1.vLookAt,
      //        ath: v1.hLookAt,
      //      },
      //      {
      //        atv: v2.vLookAt,
      //        ath: v2.hLookAt,
      //      },
      //    ]
      //  }
      //  this.hotspots.push(line)
      //  this.getFunc({
      //    target: 'FullPano',
      //    name: 'setPolygonal'
      //  })({ ...line })
      //  var start = {
      //    guid: uuid.v1(),
      //    title: '起点',
      //    atv: v1.vLookAt,
      //    ath: v1.hLookAt,
      //    distorted: false,
      //    scale: 1,
      //    zoom: false,
      //    rotate: 0,
      //    edge: 'center',
      //  }
      //  this.getFunc({
      //    target: 'FullPano',
      //    name: 'setHotspot'
      //  })({ ...start })
      //  this.hotspots.push(start)
      //  var end = {
      //    guid: uuid.v1(),
      //    title: '终点',
      //    atv: v2.vLookAt,
      //    ath: v2.hLookAt,
      //    distorted: false,
      //    scale: 1,
      //    zoom: false,
      //    rotate: 0,
      //    edge: 'center',
      //  }
      //  this.getFunc({
      //    target: 'FullPano',
      //    name: 'setHotspot'
      //  })({ ...end })
      //  this.hotspots.push(end)
      //},
      timeSort() {
        var act = this.autoTour.acts[this.editIndex]
        this.autoTour.acts.sort((a, b) => a.timeline - b.timeline)
        if (act) {
          this.editIndex = this.autoTour.acts.indexOf(act)
        }
        //this.updataStartView()
        setTimeout(() => {
          console.log('this.$refs.LineBar.renewViewData()')
          this.$refs.LineBar.renewViewData()
          this.autoTourPlayer.renew()
          this.$forceUpdate()
        })
      },
      //updataStartView() {
      //  var lastView
      //  var lastSenceGuid
      //  for (var i in this.autoTour.acts) {
      //    var a = this.autoTour.acts[i]
      //    if (a.act == 'setView') {
      //      if (a.senceGuid != lastSenceGuid) {
      //        lastSenceGuid = a.senceGuid
      //        var item = this.getItem(lastSenceGuid)
      //        lastView = {
      //          fov: item.fov,
      //          vLookAt: item.vLookAt,
      //          hLookAt: item.hLookAt,
      //          architectural: 0,
      //          pannini: 0,
      //          distortion: 0,
      //        }
      //      }
      //      if (a.inheritView) {
      //        a.startView = lastView
      //      }
      //      lastView = a.view
      //    }
      //  }
      //},
      startTimeChange() {
        var item = this.autoTour.acts[this.editIndex]
        var d = moment.duration(item.file.duration).asMilliseconds()
        if (item.startTime < 0) {
          item.startTime = 0
        }
        if (item.startTime > d) {
          item.startTime = d
        }
        var dtime = Number(item.startTime) - this.oldTime
        item.duration = Number(item.duration) - dtime
        if (item.duration < 0) {
          item.duration = d - Number(item.startTime)
        }
        if (Number(item.startTime) + Number(item.duration) > d) {
          item.duration = d - Number(item.startTime)
        }
        this.oldTime = Number(item.startTime)
      },
      endTimeChange(e) {
        console.log(e)
      },
      setAudioStartTime() {
        var item = this.autoTour.acts[this.editIndex]
        var st = Number(item.startTime)
        item.startTime = Number((this.$refs.audio[0].currentTime * 1000).toFixed());
        var dst = Number(item.startTime) - st
        item.duration = Number(item.duration) - dst
        var d = moment.duration(item.file.duration).asMilliseconds()
        if (item.duration < 0) {
          item.duration = d - Number(item.startTime)
        }
        if (Number(item.startTime) + Number(item.duration) > d) {
          item.duration = d - Number(item.startTime)
        }
      },
      setAudioEndTime() {
        this.autoTour.acts[this.editIndex].duration = Number((this.$refs.audio[0].currentTime * 1000).toFixed()) - this.autoTour.acts[this.editIndex].startTime
      },
      endTime(item) {
        return {
          get t() {
            return +item.startTime + +item.duration
          },
          set t(val) {
            item.duration = +val - +item.startTime
          }
        }
      },
      testAudio() {
        var item = this.autoTour.acts[this.editIndex]
        var audio = this.$refs.audio[0]
        console.log('testAudio', Number(item.startTime) / 1000)
        audio.currentTime = Number(item.startTime) / 1000
        audio.play()
        this.audioEnd = setTimeout(() => {
          audio.pause()
          this.audioEnd = null
        }, item.duration)
      },
      clearAudioEnd() {
        clearTimeout(this.audioEnd)
        this.audioEnd = null
      },
      formatView(view) {
        return {
          fov: view.fov,
          vLookAt: view.vlookat,
          hLookAt: view.hlookat,
          architectural: view.architectural,
          pannini: view.pannini,
          distortion: view.distortion,
        }
      },
      getItem(guid) {
        return this.getFunc({ target: 'Data', name: 'getItem' })(guid)
      },
      lookAt(view, senceGuid) {
        if (this.senceGuid == senceGuid) {
          this.getFunc({ target: 'FullPano', name: 'tweenView' })(view)
        } else {
          this.getFunc({ target: 'Data', name: 'toPano' })(senceGuid, view)
        }
      },
      getTotalDuration() {
        var t = 0
        for (var i in this.autoTour.acts) {
          var a = this.autoTour.acts[i]
          if (a.timeline > t) {
            t = +a.timeline
          }
          if (a.duration && +a.timeline + +a.duration > t) {
            t = +a.timeline + +a.duration
          }
        }
        return t
      },
      setPrevViewToStartView(index) {
        console.log('setPrevViewToStartView')
        for (var i = index; i >= 0; i--) {
          console.log(i, this.autoTour.acts[i].type)
          if (this.autoTour.acts[i].act == 'setView') {
            this.autoTour.acts[index].startView = this.autoTour.acts[i].view
          }
        }
        this.edit(index)
      },
      setStartView(index) {
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        console.log(view, index)
        var a = this.autoTour.acts[index]
        a.startView = this.formatView(view)
        this.removeHotspots()
        //  setTimeout(() => { this.setViewLine(a.startView, a.view) })
      },
      setView() {
        if (this.editIndex) {
          if (this.frameIndex) {
            var act = this.autoTour.acts[this.editIndex]
            var frame = act.keyFrames[this.frameIndex]
            var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
            frame.view = this.formatView(view)
          } else {
            this.addFrameTime(this.$refs.LineBar.getCenter())
          }
        }
        //  console.log(view)
        //  var a = this.autoTour.acts[index]
        //  a.view = this.formatView(view)
        //  this.timeSort()
        //  this.removeHotspots()
        //  setTimeout(() => { this.setViewLine(a.startView, a.view) })
      },
      change() {
        this.$emit('change', this.autoTour)
      },
      newAutoTour() {
        return {
          acts: [],
          rows: this.newRows()
        }
      },
      newRow(r) {
        var guid = uuid.v4()
        return {
          guid: guid,
          name: '',
          minH: 0,
          type: null,
          key: 'rowGuid',
          value: guid,
          dragable: true,
          ...r
        }
      },
      newRows() {
        return [this.newRow({ name: '动画1', type: 'view', minH: 1 }), this.newRow({ name: '音频1', type: 'audio', minH: 1, }), this.newRow({ name: '附加1', type: 'addon', minH: 1, }),]
      },
      newAct(obj) {
        return {
          act: 'setView',
          rowGuid: null,
          tweenType: 'EaseOutQuad',
          timeline: 0,
          duration: 5000,
          text: '',
          keyFrames: [],
          ...obj,
          guid: uuid.v1(),
        }
      },
      getLastIndex(t, type) {
        var time = Number(Number(t).toFixed(0))

        var index = null
        for (var i in this.autoTour.acts) {
          var a = this.autoTour.acts[i]
          if (a.timeline < time) {
            if (type) {
              if (a.act == type) {
                index = i
              }
            } else {
              index = i
            }
          }
        }
        return index
      },
      getRowsByType(type) {
        var rows = []
        for (var i in this.autoTour.rows) {
          var r = this.autoTour.rows[i]
          if (r.type == type) {
            rows.push(r)
          }
        }
        return rows
      },
      getRowByType(type) {
        for (var i in this.autoTour.rows) {
          var r = this.autoTour.rows[i]
          if (r.type == type) {
            return r
          }
        }
        return null
      },
      addActTime(t, type) {
        var time = Number(Number(t).toFixed(0))

        var act = this.newAct({ act: type })
        act.timeline = time
        switch (type) {
          case 'setView':
            act.senceGuid = this.senceGuid
            act.blend = 'BLEND(1.0, easeInCubic)'
            var row = this.getRowByType('view')
            act.rowGuid = row.guid || null
            var pano = this.getItem(this.senceGuid)
            console.log(pano, this.senceGuid, moment.duration(pano.linkItem.duration).asMilliseconds())
            if (pano.linkItem.isPanoVideo) {
              act.duration = moment.duration(pano.linkItem.duration).asMilliseconds()
            }
            act.waitLoad = true
            //this.setTime(act.timeline + act.duration)
            break
          case 'subtitle':
            var row = this.getRowByType('addon')
            act.rowGuid = row.guid || null
            act.duration = 2000
            setTimeout(() => {
              this.$refs.subtitleText[0].focus()
            })
            break
          case 'viewText':
            var row = this.getRowByType('addon')
            act.rowGuid = row.guid || null
            act.textAlign = 'center'
            act.blendMode = 'normal'
            act.edge = 'center'
            setTimeout(() => {
              this.$refs.textText[0].focus()
            })
            break
          case 'pausePoint':
            var row = this.getRowByType('addon')
            act.rowGuid = row.guid || null
            act.textAlign = 'center'
            act.pauseMode = 'manual'
            act.duration = 0;
            act.text = '现在你可以滑动屏幕查看全景'
            break
          default:
        }
        var editIndex = this.autoTour.acts.push(act) - 1
        this.edit(editIndex)
        this.timeSort()
      },
      getDefaultViewImageFrame() {
        return {
          time: 0,
          rect: {
            width: 80,
            height: 80,
            x: 0,
            y: 0,
          },
          rotate: {
            x: 0, y: 0, z: 0,
          },
          opacity: 1
        }
      },
      addFrameTime(t, obj) {
        if (this.editIndex == null) {
          return
        }
        var act = this.autoTour.acts[this.editIndex]
        var R = this.$refs.LineBar.getRatio()
        var limit = R * 7
        if (t < act.timeline && t > act.timeline - limit) {
          t = act.timeline
        } if (t > act.timeline + act.duration && t < act.timeline + act.duration + limit) {
          t = act.timeline + act.duration
        }
        var f = this.getNewFrame(act, t, obj)
        if (t >= act.timeline && t <= act.timeline + act.duration) {
          if (!act.keyFrames) {
            act.keyFrames = []
          }
          f.time = Number(f.time.toFixed(0))
          var i = act.keyFrames.push(f)
          this.frameIndex = i - 1
          this.editFrame = f
          console.log('addFrameTime', i)
          this.frameSort()
        }
      },
      getNewFrame(act, t, obj) {
        var f = this.newFrame({ ...obj, time: t - act.timeline })
        switch (act.act) {
          case 'setView':
            var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
            f.view = this.formatView(view)
            break
          case 'playAudio':
            if (f.volume == null) {
              f.volume = 1
            }
            break
          case 'subtitle':
            break
          case 'viewText':
            var kf = new keyFrames(act.keyFrames, ViewText.DefaultViewTextFrame, act.duration, null, null, null)
            f = kf.getFrame(t - act.timeline)
            f.time = Number(f.time.toFixed(0))
            f.fontSize = Number(f.fontSize.toFixed(0))
            if (f.border) {
              f.border.width = Number(f.border.width.toFixed(0))
            }
            for (var i in f.testShadows) {
              var ts = f.testShadows[i]
              ts.x = Number(ts.x.toFixed(0))
              ts.y = Number(ts.y.toFixed(0))
              ts.blurRadius = Number(ts.blurRadius.toFixed(0))
              ts.spreadRadius = Number(ts.spreadRadius.toFixed(0))
            }
            break
          case 'setHotspot':
            var kf = new keyFrames(act.keyFrames, SetHotspot.DefaultViewTextFrame, act.duration, null, null, null)
            f = kf.getFrame(t - act.timeline)
            f.time = Number(f.time.toFixed(0))
            break
          case 'viewImage':
            var kf = new keyFrames(act.keyFrames, ViewImage.DefaultViewImageFrame, act.duration, null, null, null)
            f = kf.getFrame(t - act.timeline)
            f.time = Number(f.time.toFixed(0))
            break
          default:
        }
        return f
      },
      frameSort() {
        var act = this.autoTour.acts[this.editIndex]
        var frame = act.keyFrames[this.frameIndex]
        act.keyFrames.sort((a, b) => a.time - b.time)
        if (act) {
          this.frameIndex = act.keyFrames.indexOf(frame)
          this.editFrame = frame
        }
      },
      removeFrame(index) {
        this.frameIndex = null
        this.editFrame = null
        var act = this.autoTour.acts[this.editIndex]
        if (!(act.keyFrames && act.keyFrames[index])) {
          return
        }
        act.keyFrames.splice(index, 1)
      },
      newFrame(obj) {
        return {
          time: 0,
          tweenType: 'EaseOutQuad',
          postprocessing: null,
          ...obj
        }
      },
      frameClick(frame, index) {
        var timeline = this.autoTour.acts[this.editIndex].timeline
        this.setTime(timeline + frame.time)
        this.checkEditFrame(timeline + frame.time)
      },
      frameMoveEnd(dt, frame, index) {
        this.frameSort()
      },
      addAct(index) {
        this.act.senceGuid = this.senceGuid
        this.act.blend = 'BLEND(1.0, easeInCubic)'
        if (this.autoTour.acts && this.autoTour.acts.length >= 1 && index > 0 && index <= this.autoTour.acts.length) {
          this.act.startView = this.autoTour.acts[index - 1].view
          this.act.timeline = Number(this.autoTour.acts[index - 1].timeline) + Number(this.autoTour.acts[index - 1].duration)
        } else {
          this.act.startView = {
            fov: this.pano.fov || 120,
            vLookAt: this.pano.vLookAt,
            hLookAt: this.pano.hLookAt,
            architectural: 0,
            pannini: 0,
            distortion: 0,
          }
          this.act.timeline = 0
        }
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        this.act.view = this.formatView(view)
        //this.autoTour.acts.push(this.act)
        if (this.autoTour.acts[index]) {
          this.autoTour.acts[index].startView = this.act.view
        }
        for (var i in this.autoTour.acts) {
          if (i >= index && this.autoTour.acts[i].act == 'setView') {
            this.autoTour.acts[i].timeline = Number(this.autoTour.acts[i].timeline) + Number(this.act.duration)
          }
        }
        this.autoTour.acts.splice(index, 0, this.act)
        this.edit(index)
        //this.editIndex = index
        this.change()
        this.act = this.newAct()
      },
      removeAct(index) {
        this.edit(null)
        if (!(this.autoTour.acts && this.autoTour.acts[index])) {
          return
        }
        this.autoTour.acts.splice(index, 1)
        this.autoTourPlayer.renew()
        //  var dTime = Number(this.autoTour.acts[index].duration)
        //  if (this.autoTour.acts && this.autoTour.acts[index] && index > 0 && index <= this.autoTour.acts.length) {
        //    this.act.timeline = Number(this.autoTour.acts[index - 1].timeline) + Number(this.autoTour.acts[index - 1].duration)
        //  }
        //  for (var i in this.autoTour.acts) {
        //    if (i >= index && this.autoTour.acts[i].act == 'setView') {
        //      this.autoTour.acts[i].timeline = Number(this.autoTour.acts[i].timeline) - dTime
        //    }
        //  }
      },
      addSubtitle(index) {
        this.act.act = 'subtitle'
        if (this.autoTour.acts && this.autoTour.acts.length >= 1 && index > 0 && index <= this.autoTour.acts.length) {
          this.act.timeline = Number(this.autoTour.acts[index - 1].timeline) + Number(this.autoTour.acts[index - 1].duration)
        } else {
          this.act.timeline = 0
        }
        this.autoTour.acts.splice(index, 0, this.act)
        this.edit(index)
        this.act = this.newAct()
      },
      addText(index) {
        this.act.act = 'viewText'
        if (this.autoTour.acts && this.autoTour.acts.length >= 1 && index > 0 && index <= this.autoTour.acts.length) {
          this.act.timeline = Number(this.autoTour.acts[index - 1].timeline) + Number(this.autoTour.acts[index - 1].duration)
        } else {
          this.act.timeline = 0
        }
        this.autoTour.acts.splice(index, 0, this.act)
        this.edit(index)
        this.act = this.newAct()
      },
      replaceFile() {
        setTimeout(() => {
          this.$refs.FileLib.showDialog((file) => {
            var act = this.autoTour.acts[this.editIndex]
            act.file = file
            this.$forceUpdate()
          });
        })
      },
      addFile(t, type) {
        var time = Number(Number(t).toFixed(0))

        this.fileType = type
        this.fileTime = time
        setTimeout(() => {
          this.$refs.FileLib.showDialog();
        })
      },
      FileLibSelected(select) {
        var time = this.fileTime
        switch (this.fileType) {
          case 'Audio':
            this.act.act = 'playAudio'
            this.act.startTime = 0
            this.act.duration = moment.duration(select.duration).asMilliseconds()
            var row = this.getRowByType('audio')
            this.act.rowGuid = row.guid || null
            this.act.waitLoad = true
            this.act.canWait = true
            break
          case 'Image':
            this.act.act = 'viewImage'
            var row = this.getRowByType('addon')
            this.act.rowGuid = row.guid || null
            this.act.text = select.name
            this.act.blendMode = 'normal'
            break
          default:
            this.act.act = 'file'
            var row = this.getRowByType('addon')
            this.act.rowGuid = row.guid || null

        }
        this.act.file = select;
        this.act.timeline = time
        var i = this.autoTour.acts.push(this.act)
        this.edit(i - 1)
        this.timeSort()
        this.change()
        this.act = this.newAct()
        this.fileTime = null
      },
      cutAct(t) {
        var time = Number(Number(t).toFixed(0))

        var oldact = this.autoTour.acts[this.editIndex]
        var newact = this.newAct(oldact)
        newact.timeline = time
        newact.duration = oldact.timeline + oldact.duration - time
        oldact.duration = time - oldact.timeline
        switch (oldact.act) {
          case 'playAudio':
            newact.startTime = oldact.startTime + (time - oldact.timeline)
            break
          case 'setView':
            var k = 1 - ((time - oldact.timeline) / oldact.duration)
            var mView = this.cView(oldact.view, oldact.startView, k)
            var mView2 = this.cView(oldact.view, oldact.startView, k)
            oldact.view = mView
            newact.startView = mView2
            break
          default:
        }
        var i = this.autoTour.acts.push(newact)
        this.edit(i - 1)
        this.timeSort()
        this.change()

      },
      cView(view, view2, k) {
        var r = {}
        for (var i in view) {
          r[i] = (view2[i] - view[i]) * k + view[i]
        }
        return r
      },
      copyAct() {
        var oldact = this.autoTour.acts[this.editIndex]
        var newact = this.newAct(this.deepCopy(oldact))
        var i = this.autoTour.acts.push(newact)
        this.edit(i - 1)
        this.timeSort()
        this.change()
      },
      deepCopy(obj) {
        var r
        switch (typeof obj) {
          case 'object':
            if (Array.isArray(obj)) {
              r = []
              for (var i in obj) {
                r.push(this.deepCopy(obj[i]))
              }
            } else if (obj == null) {
              r = obj
            } else {
              r = {}
              for (var i in obj) {
                r[i] = this.deepCopy(obj[i])
              }
            }
            break
          default:
            r = obj
        }
        return r
      },
      hotspotMoveCenter() {
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        console.log(view)
        var h = this.autoTour.acts[this.editIndex].hotspot
        h.ath = view.hlookat
        h.atv = view.vlookat
        this.autoTourPlayer.currentTime = this.autoTourPlayer.currentTime
      },
      addHotspot(t) {
        var time = Number(Number(t).toFixed(0))

        this.fileTime = time
        this.$refs.HotspotIconLib.showDialog((select) => {
          this.act.senceGuid = this.senceGuid
          this.act.act = 'setHotspot'
          this.act.timeline = time
          var hotspot = this.newHotspot()
          hotspot.icon = select
          this.act.hotspot = hotspot

          var row = this.getRowByType('addon')
          this.act.rowGuid = row.guid || null

          var editIndex = this.autoTour.acts.push(this.act) - 1
          this.edit(editIndex)
          this.timeSort()
          this.toolbar = 'hotspotEdit'
          setTimeout(() => {
            console.log(this.$refs.hotspotTitle[0])
            this.$refs.hotspotTitle[0].focus()
          })

          this.act = this.newAct()
          this.fileTime = null
        });
      },
      HotspotIconLibSelected(select) {
        var h = this.autoTour.acts[this.editIndex].hotspot
        h.icon = select;
        this.setHotspot(h)
      },
      showHotspotIconLibDialog() {
        this.$refs.HotspotIconLib.showDialog();
      },
      newHotspot(setting) {
        var guid = uuid.v1();
        var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
        return {
          ath: view.hlookat || 0,
          atv: view.vlookat || 0,
          description: "",
          distorted: false,
          file: null,
          gpsLat: 0,
          gpsLng: 0,
          guid: guid,
          icon: {
            guid: null,
            width: 0,
            height: 0,
            fps: 0,
            isAnimation: false,
            edge: 'top',
            imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            //enableText: true,
            textOffsetX: 0,
            textOffsetY: 0,
            textEdge: 'bottom'
          },
          linkFOV: 120,
          linkHLookAt: 0,
          linkItemGUID: null,
          linkType: 'Pano',
          linkVLookAt: 0,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          rz: 0,
          rx: 0,
          ry: 0,
          loop: false,
          inheritView: false,
          showTitle: true,
          sortID: 2,
          title: "",
          type: 'Image',
          url: null,
          zoom: false,
          ...setting
        }
      },
      numberMath(val, step, max, min) {
        val = Number(val)
        step = Number(step)
        val += step
        if (max && val > max) {
          val = max;
        }
        if (min && val < min) {
          val = min;
        }
        return Number(val);
      },
    },
  }
</script>
<style scoped>
  .EditAutoTour {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .baseArea {
    pointer-events: auto;
    background-color: #0008;
    width: 100%;
    color: white
  }

  .autoTourPlayer {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: baseline;
  }

    .autoTourPlayer > .left {
      flex: none;
      width: 110px;
      font-size: 12px;
    }

    .autoTourPlayer > .middle {
      flex: none;
      padding: 0 0.5em;
    }

    .autoTourPlayer > .right {
      flex: none;
      width: 110px;
      text-align: right;
    }

  .lineBarItem {
    position: relative;
    width: 100%;
    height: 100%
  }

    .lineBarItem.draging.move {
      opacity: 0.5;
      cursor: grabbing;
    }

    .lineBarItem .back {
      position: absolute;
      left: -15px;
      right: -15px;
      top: -2px;
      bottom: -2px;
      background-color: white;
      border: solid #000 1px;
      box-shadow: 0px 0px 6px 0px #000;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      z-index: -1;
    }

      .lineBarItem .back.point {
        left: -7px;
        right: -7px;
        top: -2px;
        bottom: -2px;
      }

      .lineBarItem .back .lineButton {
        height: 100%;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: e-resize;
      }

        .lineBarItem .back .lineButton > * {
          width: 2px;
          height: 10px;
          border-radius: 3px;
          background-color: #999;
        }

    .lineBarItem.draging .back .lineButton {
      cursor: grabbing;
    }

    .lineBarItem .item {
      width: 100%;
      height: 100%;
      border: solid 1px #000;
      border-radius: 3px;
      background-color: #b3a3a3;
      font-size: 12px;
    }

    .lineBarItem.setView .item {
      background-color: #d78080;
    }

    .lineBarItem.playAudio .item {
      background-color: #46b6e1;
    }

    .lineBarItem.subtitle .item {
      background-color: #8a39f2;
    }

    .lineBarItem.viewText .item {
      background-color: #f36526;
    }

    .lineBarItem.viewImage .item {
      background-color: #19d71d;
    }

    .lineBarItem.setHotspot .item {
      background-color: #da2be8;
    }

    .lineBarItem.pausePoint .item {
      background-color: transparent;
    }

    .lineBarItem.active {
      z-index: 100;
    }

      .lineBarItem.active .item {
        border: none
      }

  .controlBar {
    width: 100%;
    height: 40px;
    position: relative;
    pointer-events: auto;
    background-color: #0008;
  }

  .toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0008;
  }

  .overflow-x {
    overflow-x: auto;
    display: flex;
  }

    .overflow-x > * {
      white-space: nowrap;
      flex: none;
    }

  .toorbarBtn {
    font-size: 12px;
    padding: 1px 5px;
    color: white;
  }

    .toorbarBtn.back {
      background-color: #555;
      height: 32px;
      margin-left: 3px
    }

    .toorbarBtn.active {
      background-color: #444;
    }

  .panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #000;
    color: #fff;
  }

    .panel .panelBtn {
      color: white;
    }

    .panel .switch {
      color: white;
      font-size: 14px;
    }

  .tweentypebox {
    width: 67px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 100%;
    color: white;
    font-size: 10px;
    border: 1px solid #0000;
  }

    .tweentypebox.active {
      border: 1px solid #f7ab0c;
    }

  .tweentypeimg {
    display: inline-block;
    width: 67px;
    height: 46px;
    background-repeat: no-repeat;
    background-size: 402px 276px;
    margin-bottom: -5px;
    filter: invert(100%);
  }

  .rangeBar {
    background-color: #fff5;
    padding: 5px;
    margin: 5px 0;
    text-align: center;
    font-size: 12px;
    border-radius: 0.2rem;
  }

  input.dark {
    background-color: #373737;
    color: white;
    border: none;
  }

    input.dark:focus {
      border: none;
    }

  .rectEdit {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .rectEditBox {
    position: absolute;
    border: 1px dashed #0f06;
    pointer-events: none;
  }

    .rectEditBox .dragBtn {
      background-color: #0f06;
      pointer-events: auto;
      width: 15px;
      height: 15px;
    }

      .rectEditBox .dragBtn.lt {
        position: absolute;
        left: -7px;
        top: -7px;
        cursor: nw-resize;
      }

      .rectEditBox .dragBtn.tm {
        position: absolute;
        top: -7px;
        left: calc(50% - 7px);
        cursor: n-resize;
      }

      .rectEditBox .dragBtn.rt {
        position: absolute;
        right: -7px;
        top: -7px;
        cursor: ne-resize;
      }

      .rectEditBox .dragBtn.lm {
        position: absolute;
        left: -7px;
        top: calc(50% - 7px);
        cursor: w-resize;
      }

      .rectEditBox .dragBtn.ct {
        position: absolute;
        left: calc(50% - 7px);
        top: calc(50% - 7px);
        cursor: move;
      }

      .rectEditBox .dragBtn.rm {
        position: absolute;
        right: -7px;
        top: calc(50% - 7px);
        cursor: e-resize;
      }

      .rectEditBox .dragBtn.lb {
        position: absolute;
        left: -7px;
        bottom: -7px;
        cursor: sw-resize;
      }

      .rectEditBox .dragBtn.rb {
        position: absolute;
        right: -7px;
        bottom: -7px;
        cursor: se-resize;
      }

      .rectEditBox .dragBtn.bm {
        position: absolute;
        left: calc(50% - 7px);
        bottom: -7px;
        cursor: s-resize;
      }

    .rectEditBox .hideBtn {
      background-color: #0f06;
      pointer-events: auto;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .rectEditBox .moveBtn {
      background-color: #0f06;
      pointer-events: auto;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: grab;
    }

  .ppitem {
    margin: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
  }

    .ppitem label {
      margin-right: 5px;
      flex: none;
    }

    .ppitem .nb {
      flex: auto;
      max-width: 150px;
    }

  .borderStyleView {
    width: 30px;
    height: 30px;
    border-width: 4px;
    border-color: #d48702;
    background: #eee;
    background-image: linear-gradient(45deg,#0002 25%,#0000 0,#0000 75%,#0002 0),linear-gradient(45deg,#0002 25%,#0000 0,#0000 75%,#0002 0);
    background-size: 16px 16px;
    background-position: 0 0,8px 8px;
  }

  .shadowSelect {
    display: flex;
  }

    .shadowSelect > .shadowPreview {
      flex: none;
      font-size: 12px;
      text-align: center;
      vertical-align: middle;
      border: solid 2px #fff;
      margin: 0 1px;
      width: 50px;
      height: 24px;
      background: #eee;
      background-image: linear-gradient(45deg,#0002 25%,#0000 0,#0000 75%,#0002 0),linear-gradient(45deg,#0002 25%,#0000 0,#0000 75%,#0002 0);
      background-size: 16px 16px;
      background-position: 0 0,8px 8px;
      overflow: hidden;
    }

      .shadowSelect > .shadowPreview.active {
        border: solid 2px #fd7e14;
      }

    .shadowSelect > .add {
      flex: none;
      font-size: 12px;
      text-align: center;
      border: solid 2px #fff;
      margin: 0 1px;
      width: 24px;
      height: 24px;
    }

  .pausePointI {
    height: 20px;
    width: 10px;
    /*    background-color: red;
  */ position: absolute;
    left: -4px;
    /*border-radius: 50%;*/
    background: linear-gradient(135deg, transparent 5px, red 0) top left, linear-gradient(-135deg, transparent 5px, red 0) top right, linear-gradient(-45deg, transparent 5px, red 0) bottom right, linear-gradient(45deg, transparent 5px, red 0) bottom left;
    background-size: 50% 50%;
    background-repeat: no-repeat;
  }

  .pausePointIcon {
    width: 6px;
    height: 7px;
    margin: 6px 2px;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  .pausePointB {
    height: 24px;
    width: 12px;
    /*    background-color: red;
  */ position: absolute;
    top: -2px;
    left: -5px;
    /*border-radius: 50%;*/
    background: linear-gradient(135deg, transparent 5px, #fff 0) top left, linear-gradient(-135deg, transparent 5px, #fff 0) top right, linear-gradient(-45deg, transparent 5px, #fff 0) bottom right, linear-gradient(45deg, transparent 5px, #fff 0) bottom left;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    z-index: -1;
  }
</style>
